import Header from "../../Header/Header";
import Footer from "../../Footer";
import AnimalBox from "../../AnimalBox";

import { Context } from "../../../App";
import { useContext, useEffect } from "react";

const DogoPitbull = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Dogo-Pitbull") : (document.title = "Dogo-Pitbul");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Dogs looking for sponsor",
      tr: "Sponsor arayan köpeklerimiz",
    },
    intro: {
      eng: "The following dogs are looking for sponsors. They are fully recovered and stays in pet hotel and looking for forever homes.",
      tr: "Aşağıdaki köpekler sponsor arıyor. Tüm köpeklerimiz evcil hayvan otelinde kalıyor ve ömürlük yuva arıyor.",
    },
    sponsorLinkText: {
      eng: "Click for sponsorship",
      tr: "Sponsor olmak icin tıklayın",
    },
  };

  const contentRoss = {
    name: "Ross",
    description1: {
      eng: "We found him at a shelter.  He wagged his tail and showed his belly to everyone. He probably had a family before but they abandoned him. We don't know how he ended up at the shelter but he was so sweet that we couldn't leave him there.  We got him out of the shelter, neutered, vaccinated and chipped.",
      tr: "Ross barınağa terk edilmişti. Kuyruğunu sallayıp herkese göbeğini açıyordu. Muhtemelen daha önce bir ailesi vardı ama onu terk ettiler. Barınağa nasıl geldiğini bilmiyoruz ama o kadar tatlıydı ki onu orada bırakamazdık. Barınaktan çıkardık, kısırlaştırdık,aşılattık ve çiplettik.",
    },
    description2: {
      eng: "He is currently staying in a pet hotel. We are looking for sponsors for his pet hotel, food and veterinary expenses. We are also looking for a forever home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentAtlas = {
    name: "Atlas",
    description1: {
      eng: "Atlas was abandoned to a park in a kind of way tied with a strap. His situation became a top trend on social media in Turkey, but no one saved him. He was still in the same situation as we saw him on social media ads when we went to pick him  up. We took him to the vet right away because he had injuries on his body. Probably he was used in illegal dog fights. Then we spayed and microchipped. Unfortunately, he caught canine distemper after a short time. He was a fighter and recovered from the illness within a month.",
      tr: "Atlas kayışıyla bir çocuk bahçesinin parkına bağlanıp terkedilmişti. Bu hali sosyal medyada gündem olmuş ancak kimse kurtarmamıştı. Almaya gittiğimizde hala aynı şekilde parkta duruyordu. Hemen alıp veterinere götürdük ve vücudundaki yaralardan dövüşlerde kullanıldığını çıkardık. Ardından kısırlaştırıp çiplettik. Maalesef kısa süre sonra gençlik hastalığına yakalandı ancak bır ay gibi bir sürede bu hastalığı da yendi.",
    },
    description2: {
      eng: "He is currently staying in a pet hotel. We are looking for sponsors for his pet hotel, food and veterinary expenses. We are also looking for a forever home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentKristo = {
    name: "Kristo",
    description1: {
      eng: "We found Kristo left at one of our feeding spots where we leave food and water for strays. We couldn't believe our eyes when we saw him. He was so weak you could count his bones. He ate the food we put in within seconds. Who knows how long he couldn't find food. We took him to the vet immediately. We have had his check-ups done. Unfortunately, he had anaemia due to long-term fasting. He was taken into treatment. He was also microchipped.",
      tr: "Kristoyu besleme bölgemize atılmış bulduk ve gözlerimize inanamadık. O kadar zayıftı ki kemiklerini saya biliyordunuz. Koyduğumuz mamayı saniyeler içerisinde yedi. Kimbilir ne kadar süredir yemek bulamamış ve bu hale gelmişti. Derhal kliniğe çıkardık. Kontrolleri yapıldı ve uzun süre aç kalmasından kaynaklı kansızlık tespit edildi. Tedaviye alındı. Çipleme işlemi de yapıldı.",
    },
    description2: {
      eng: "He is currently staying in a hostel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentTeo = {
    name: "Teo",
    description1: {
      eng: "Teo was abandoned at an airport in Istanbul. When we saw his ads on social media, we immediately took it from there. We learned during the veterinary check that he was used in dog fighting. His whole body was scarred and infected. Despite being hurt by people, he has never lost her trust and is incredibly social with people. He was neutered, vaccinated, chipped.",
      tr: "Teo istanbulda bir havalimanına terk edilmişti. Durumunu sosyal medyada gördüğümüzde hemen oradan aldık. Köpek dövüşünde kullanıldığını veteriner kontrolu sırasında öğrendik. Vücudunun her yeri yara içerisinde ve enfekte olmuştu. İnsanlardan zarar gördüğü halde güvenini asla kaybetmemiş ve insanlarla inanılmaz bir biçimde sosyal. Uzun süre tedavi gördü. Kısırlaştı, aşılandı, çiplendi.",
    },
    description2: {
      eng: "He is currently staying in a hostel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentLidya = {
    name: "Lidya",
    description1: {
      eng: "When we saw Lydia, she had a tightly tied strap around her neck and she was running around in a panic. She was only 4 months old when we found her. We couldn't leave her on that street then, and we immediately took her under protection. After her check-ups were done in the vet, she was vaccinated, spayed and microchipped.",
      tr: "Lidyayı gördüğümüzde boynunda sıkı bir şekilde bağlanmış bir kablo vardı ve oradan oraya koşturuyordu panik halde. Henüz 4 aylıktı bulduğumuzda. Onu o halde o sokakta bırakamazdık ve hemen korumaya aldık. Veteriner kontründen gecti, aşıları tamamlandı, kısırlaştırıldı. İnsanlar ve diğer köpeklerle uysal ve şu an 1 yaşında.",
    },
    description2: {
      eng: "She is currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentChandler = {
    name: "Chandler",
    description1: {
      eng: "Chandler was left in the shelter with his ears cut off and scarred all over him. In the shelter, it was impossible to treat his wounds and it was possible for him to catch other diseases. That's why we took him out of the shelter as soon as we saw him. He had a blood parasite. He was treated for 1.5 months. After the wounds on his body and the infection in his ears healed, he was vaccinated, neutered and microchipped.",
      tr: "Chandler kulakları kesilmiş ve her yeri yara içerisinde barınağa terk edilmişti. Barınakta yaralarının tedavi edilmesi imkansız olduğu gibi başka hastalıklar kapması da mümkündü. Bu yuzden gördüğümüz an barınaktan çıkardık. Kan paraziti vardi. 1.5 ay boyunca tedavi gördü. Vücudundaki yaralar ve kulaklarındaki enfeksiyon iyileştikten sonra aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    description2: {
      eng: "He is currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentRachel = {
    name: "Rachel",
    description1: {
      eng: "We found Rachel lying in a corner of the shelter and about to die. It was so obvious that she once had an owner, in every way. We learned from the authorities that she had not eaten for a long time, and we immediately took her to a vet for a checkup. She had a blood parasite. She was treated for 1.5 months. She regained her trust in people. For Rachel, those days were just a bad memory. She was neutered, vaccinated, and microchipped.",
      tr: "Rachel’i barınakta bir köşede ölüme yatmış bulduk. Bir zamanlar bir sahibi olduğu o kadar belliydi ki her halinden. Yetkililerden uzun süredir yemek yemediğini öğrendik ve derhal bir veterinere kontole götürdük. Kan paraziti vardi. 1.5 ay boyunca tedavi gördü. Yeniden insanlara güven duydu. Rachel için o günler sadece kötü bir anı olarak kaldı.  Kısırlaştı, aşılandı, çiplendi.",
    },
    description2: {
      eng: "She is currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentKuzey = {
    name: "Kuzey",
    description1: {
      eng: "We saw Kuzey’s ads on social media. He was tied up next to a public toilet and mouldy bread was placed in front of him to eat. There wasn't even water. We immediately went to the place where he was and took him from there for veterinary control. He had scabies in his ears and anaemia. He was treated and subsequently vaccinated, spayed and microchipped.",
      tr: "Kuzeyin ilanini sosyal medyada gördük. Bir umumi tuvaletin yanına bağlanmış ve önüne küflü ekmek konmuştu yemesi için. Su bile yoktu. Hemen bulunduğu yere gidip veteriner kontrolu için oradan aldık. Kulaklarında uyuz ve kansızlık vardı. Tedavi gördü ve sonrasında aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    description2: {
      eng: "He is currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentToros = {
    name: "Toros",
    description1: {
      eng: "Toros was abandoned on a mountain and badly attacked by the dogs in the area where he was left. When we heard his news,  we immediately went and took him from there, and when we saw him, both his ears were cut off already and his bones were counted. He was taken to vet for checkup and fortunately, he only had anaemia due to starvation. He was treated and subsequently vaccinated, spayed and microchipped.",
      tr: "Toros bir dağ başına atılmış ve atıldığı bölgedeki köpekler tarafından çok kötü saldırıya uğramıştı. İhbarı geldiğinde hemen gidip oradan aldık ve gördüğümüzde hem kulakları kesilmiş hem de kemikleri sayılıyordu. Veteriner kontrolünden geçti ve şanslıydık ki aç kalmasına bağlı olarak sadece kansızlığı vardı. Tedavi gördü ve sonrasında aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    description2: {
      eng: "He is currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const imagesRoss = [
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-1.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-2.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-3.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-4.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-5.jpg") },
  ];

  const imagesAtlas = [
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-1.png") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-1.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-2.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-3.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-4.jpg") },
  ];

  const imagesKristo = [
    { url: require("../../../assets/dogs/dogo-pitbull/kristo/kristo-5.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kristo/kristo-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kristo/kristo-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kristo/kristo-3.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kristo/kristo-4.png") },
  ];

  const imagesTeo = [
    { url: require("../../../assets/dogs/dogo-pitbull/teo/teo-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/teo/teo-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/teo/teo-3.jpeg") },
  ];

  const imagesLidya = [
    { url: require("../../../assets/dogs/dogo-pitbull/lidya/lidya-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/lidya/lidya-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/lidya/lidya-3.jpeg") },
  ];

  const imagesRachel = [
    { url: require("../../../assets/dogs/dogo-pitbull/rachel/rachel-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/rachel/rachel-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/rachel/rachel-3.jpeg") },
  ];

  const imagesChandler = [
    { url: require("../../../assets/dogs/dogo-pitbull/chandler/chandler-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/chandler/chandler-2.jpeg") },
  ];

  const imagesKuzey = [
    { url: require("../../../assets/dogs/dogo-pitbull/kuzey/kuzey-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kuzey/kuzey-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kuzey/kuzey-3.jpeg") },
  ];

  const imagesToros = [
    { url: require("../../../assets/dogs/dogo-pitbull/toros/toros-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/toros/toros-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/toros/toros-3.jpeg") },
  ];

  return (
    <main>
      <Header />
      <div className="container half-width-container">
        <div className="justify">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <a href="https://form.jotform.com/220504169691052" target="_blank" rel="noreferrer">
            {language.lang === "eng" ? content.sponsorLinkText.eng : content.sponsorLinkText.tr}
          </a>
        </div>
        <AnimalBox images={imagesRoss} content={contentRoss} />
        <AnimalBox images={imagesAtlas} content={contentAtlas} />
        <AnimalBox images={imagesKristo} content={contentKristo} />
        <AnimalBox images={imagesTeo} content={contentTeo} />
        <AnimalBox images={imagesLidya} content={contentLidya} />
        <AnimalBox images={imagesRachel} content={contentRachel} />
        <AnimalBox images={imagesChandler} content={contentChandler} />
        <AnimalBox images={imagesKuzey} content={contentKuzey} />
        <AnimalBox images={imagesToros} content={contentToros} />
      </div>
      <Footer />
    </main>
  );
};

export default DogoPitbull;
