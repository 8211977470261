import Header from "../../Header/Header";
import Footer from "../../Footer";
import { Context } from "../../../App";
import { useContext, useEffect } from "react";
import img1 from "../../../assets/holdmypaw/paris/paris1.jpeg";
import img2 from "../../../assets/holdmypaw/paris/paris2.jpeg";
import img3 from "../../../assets/holdmypaw/paris/paris3.jpeg";
import img4 from "../../../assets/holdmypaw/paris/paris4.jpeg";
import img5 from "../../../assets/holdmypaw/paris/paris5.jpeg";
import img6 from "../../../assets/holdmypaw/paris/paris6.jpeg";
import img7 from "../../../assets/holdmypaw/paris/paris7.jpeg";
import img8 from "../../../assets/holdmypaw/paris/paris8.jpeg";
const Paris = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Paris") : (document.title = "Paris");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Paris",
      tr: "Paris",
    },
    intro1: {
      eng: "Hi, my name is Paris.My rescuer found me in a high school garden in Turkey with my 7 little babies. I actually was born into the same school garden, but had a car accident one day. The teachers who gave me food and water had me treated, but since I was not spayed, I had already gotten pregnant.",
      tr: "Merhaba benim adım Paris. Kurtarıcım beni Türkiye'de bir lise bahçesinde 7 küçük bebeğimle buldu. Aslında aynı okul bahçesinde doğdum ama bir gün trafik kazası geçirdim. Bana yemek ve su veren öğretmenler beni tedavi ettirdi ama kısırlaştırılmadığım için çoktan hamile kalmıştım."
    },
    intro2: {
      eng: "When my puppies started to grow, guardians of the students complained about me with false accusations and it caused my path to cross with my real rescuers. They found me a fenced garden where I can be safe.",
      tr: "Yavrularım büyümeye başlayınca öğrenci velilerinin beni asılsız suçlamalarla şikayet etmesi, gerçek kurtarıcılarımla yolumun kesişmesine neden oldu. Bana güvende olabileceğim çitle çevrili bir bahçe buldular."},
    intro3: {
      eng: "One day, I learned that I would have a family in Canada and I will have a warm home one day. I was very excited, may be I would have also some doggie friends too. So my rescuer took me to the vet to see if I'm healthy before my travel.",
      tr: "Bir gün Kanada'da bir ailem olacağını ve sıcacık bir yuvam olacağını öğrendim. Çok heyecanlandım, belki benim de köpek arkadaşlarım olacakti. Bu yüzden kurtarıcım, seyahatimden önce sağlıklı olup olmadığımı görmek için beni veterinere götürdü."
    },
    intro4: {
			eng: "Unfortunately, I was low heart worm. I needed to start the treatment but the cost is very expensive. I know that my rescuer can't afford it so can you help me to cross this hurdle too?",
			tr: "Maalesef düşükte olsa kalp kurdu hastaligim varmış. Tedaviye başlamam gerekiyor ama maliyeti çok pahalı. Kurtarıcımın bunu karşılayamayacağını biliyorum, bu yüzden bu engeli aşmama da yardım edebilir misin?"
		},
    intro5: {
      eng: "Thank you so much for listening my story. If you share my post on your accounts, maybe it helps me to collect the amount earlier than we though. Why not?",
      tr: "Hikayemi dinlediğiniz için çok teşekkür ederim. Gönderimi hesaplarınızda paylaşırsanız, belki de düşündüğümüzden daha önce tutarı toplamama yardımcı olur. Neden olmasın?"
    },
    intro6: {
      eng: "You can donate for my medicine from the following fundraising links:",
      tr: "İlacım için aşağıdaki bağış toplama linklerinden bağışta bulunabilirsiniz:"
    },
    buttonText1: {
      eng: "Internationally",
      tr: "Yurtdisi"
    },
    buttonText2: {
      eng: "Domestically",
      tr: "Yurtici"
    },
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container background-image">
        <div className="intro holdmypaw">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro1.eng : content.intro1.tr}</p>
          <div className="imageContainer">
            <img src={img1} alt="asd"></img>
            <img src={img2} alt="asd"></img>
          </div>
          <p>{language.lang === "eng" ? content.intro2.eng : content.intro2.tr}</p>
          <div className="imageContainer">
            <img src={img3} alt="asd"></img>
            <img src={img4} alt="asd"></img>
          </div>
          <p>{language.lang === "eng" ? content.intro3.eng : content.intro3.tr}</p>
          <div className="imageContainer">
            <img src={img5} alt="asd"></img>
            <img src={img6} alt="asd"></img>
          </div>

          <p>{language.lang === "eng" ? content.intro4.eng : content.intro4.tr}</p>
          <div className="imageContainer">
            <img src={img7} alt="asd"></img>
            <img src={img8} alt="asd"></img>
          </div>
          <p>{language.lang === "eng" ? content.intro5.eng : content.intro5.tr}</p>
          <p>{language.lang === "eng" ? content.intro6.eng : content.intro6.tr}</p>
          <div className="imageContainer">
            <button className="donation-button">
              <a href="https://cuddly.com/donate/4920661/paris" target="_blank" rel="noopener noreferrer">
                {language.lang === "eng" ? content.buttonText1.eng : content.buttonText1.tr}
              </a>
            </button>
            <button className="donation-button">
              <a
                href="https://fonzip.com/birpatidesentut/bir-pati-de-sen-tut"
                target="_blank"
                rel="noopener noreferrer"
              >
                {language.lang === "eng" ? content.buttonText2.eng : content.buttonText2.tr}
              </a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Paris;
