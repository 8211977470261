import { Context } from "../../App";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import paris from "../../assets/holdmypaw/paris/paris.jpeg";
import dogsInNeed from "../../assets/holdmypaw/dogsInNeed/dogsInNeed.png";
import Header from "../Header/Header";
import Footer from "../Footer";

const HoldMyPaw = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Hold My Paw") : (document.title = "Patimden Tut");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    paris: {
      title: {
        eng: "Paris",
        tr: "Paris",
      },
      intro: {
        eng: "Paris was recently tested late Mar 2023 and low positive on heart worm. She will be starting treatment. If her treatment is not completed by the time she is adopted, her medicine will be sent with her to finish out the course prescribed.",
        tr: "Parise kısa süre önce Mart 2023'ün sonlarında test yaptırdık ve kalp kurdu açısından düşük pozitif çıktı. Tedaviye başlayacak. Yuvalanana kadar tedavisi tamamlanmazsa, reçete edilen ilaçları yanında göndereceğiz."
      },
      quote: {
        eng: "We are making a huge effort to find a home for her and this is the last obstacle for her to go to home. We would be pleased if you want to help us to collect the necessary amount. You can read her full history => ",
        tr: "Ona bir yuva bulmak için büyük çaba harcıyoruz ve bu onun eve gitmesinin önündeki son engel. Gerekli miktarı toplamamıza yardımcı olmak isterseniz memnun oluruz. Tüm geçmişini buradan okuyabilirsiniz =>"
      },
    },
    dogsInNeed: {
      title: {
        eng: "Dost-Findik-Silva",
        tr: "Dost-Findik-Silva",
      },
      intro: {
        eng: "These 3 bodies were rescued from very bad conditions and got through a lot. They are safe but they need your help for their castration operation, 4D and 3D snap tests, X-rays, hemograms and vaccinations.",
        tr: "Bu 3 arkadas çok kötü koşullardan kurtarıldı ve çok şey atlattı. Güvendeler ama kisirlastirma ameliyatı, 4D ve 3D snap testleri, röntgen, hemogram ve aşıları için sizin yardımınıza ihtiyaçları var."
      },
      finalSentence: {
        eng: "They will have a home in Canada but they should have all the above done before travel. Can you help them to change their life forever? Click if you want to know more about them. =>",
        tr: "Kanada'da bir yuvaları olacak ama seyahatten önce bu testlerin yapilmasi ve kisirlastirilmalari gerekiyor. Hayatlarını sonsuza dek değiştirmemize yardım eder misiniz? Onlar hakkında daha fazla bilgi edinmek istiyorsanız tıklayın. => "
      },
    },
  };

  return (
    <main>
      <Header />
      <div className="container dogs-container">
        <Link to="/holdmypaw/paris">
          <div className="dogs-column">
            <div className="image-container">
              <img src={paris} alt="Paris"></img>
            </div>
            <div className="content-container">
              <h1>{language.lang === "eng" ? content.paris.title.eng : content.paris.title.tr}</h1>
              <p>{language.lang === "eng" ? content.paris.intro.eng : content.paris.intro.tr}</p>
              <p>{language.lang === "eng" ? content.paris.quote.eng : content.paris.quote.tr}</p>
            </div>
          </div>
        </Link>
        <Link to="/holdmypaw/dogsInNeed">
          <div className="dogs-column">
            <div className="image-container">
              <img src={dogsInNeed} alt="How you can help?"></img>
            </div>
            <div className="content-container">
              <h1>
                {language.lang === "eng" ? content.dogsInNeed.title.eng : content.dogsInNeed.title.tr}
              </h1>
              <p>
                {language.lang === "eng" ? content.dogsInNeed.intro.eng : content.dogsInNeed.intro.tr}
              </p>
              <p>
                {language.lang === "eng"
                  ? content.dogsInNeed.finalSentence.eng
                  : content.dogsInNeed.finalSentence.tr}
              </p>
            </div>
          </div>
        </Link>
      </div>
      <Footer />
    </main>
  );
};

export default HoldMyPaw;
