import { Context } from "../../App";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import dogo from "../../assets/dogs/dogo.jpg";
import cases from "../../assets/dogs/cases.jpg";
import adopt from "../../assets/dogs/adopt.jpg";
import Header from "../Header/Header";
import Footer from "../Footer";
const Dogs = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Dogs") : (document.title = "Köpekler");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentENG = {
    dogoPitbull: {
      title: "Dogo Argentino - Pitbull-Terrier",
      firstParagraph:
        "Turkey authorities signed a law in January 2022 which sentenced Amerikan Pitbull Terrier, Dogo Argentino, Fila Brasileiro, Japanese Tosa, American Staffordshire Terrier and American Bully to death.",
      secondParagraph:
        "These breeds are banned in Turkey for many years but as there is no applicable law about, they have been abused by people for many years by using them in dog fights or illegal production in very bad conditions.",
      lastParagraph:
        "With this recent law, these dogs were abandoned to shelters, parking areas, public gardens and even in the middle of nowhere. January 2022 is a black mark in Turkish history. Volunteers and charities like us stepped in and rescued as many dogs as they could.",
      description: " Curabitur vitae quam et dui luctus consectetur.est consectetur ultricies. ",
    },
    cases: {
      title: "Cases",
      firstParagraph:
        "Dogs can't take care of themselves if they are injured or become sick. Little puppies can't take care of themselves at all. Thousands of dogs and puppies are hurt, lost, or abandoned everyday in the world. We do our best to help as many as we possibly can with the resources we have. You can find some of many rescue stories here.",
      lastParagraph:
        "Don’t forget, saving one dog will not change the world, but surely for that one dog, the world will change forever.",
    },
    adoption: {
      title: "Adoption",
      firstParagraph:
        "Unfortunately, in many parts of the world animals are stray with no one to take care of them or are being abandoned by their owners. Many of them become ill, injure or suffer abuses on the streets and require doctors, medicine, surgeries, and cannot survive.",
      lastParagraph:
        "One of the main goals of Bir Pati De Sen Tut is to find a best home for strays who cannot survive. Our motivation is to give them a life they deserve. You can find available dogs to be adopted here.",
    },
  };

  const contentTR = {
    dogoPitbull: {
      title: "Dogo Argentino - Pitbull-Terrier",
      firstParagraph:
        "Ocak 2022'de Türk makamları Amerikan Pitbull Terrier, Dogo Argentino, Fila Brasileiro, Japon Tosa, Amerikan Staffordshire Terrier ve Amerikan Bully'yi ölüme mahkum eden yasaya imza attılar.",
      secondParagraph:
        "Bu ırklar Türkiye'de uzun yıllardır yasaklanmış ancak yürürlükte bir kanun olmadığı için uzun yıllardır insanlar tarafından köpek dövüşlerinde ya da çok kötü koşullarda yasa dışı üretimde kullanılarak suistimal edilmiştir.",
      lastParagraph:
        "Bu son yasa ile bu köpekler barınaklara, park alanlarına, halk bahçelerine ve hatta ıssız yerlere terk edildi. Ocak 2022, Türk tarihinde bir kara lekedir. Bizim gibi gönüllüler ve hayır kurumları devreye girdi ve olabildiğince çok köpeği kurtardı.",
    },
    cases: {
      title: "Vakalar",
      firstParagraph:
        "Köpekler yaralanırlarsa veya hastalanırlarsa kendilerine bakamazlar. Yavru köpekler kendilerine hiç bakamazlar. Dünyada her gün binlerce yetişkin köpek ve yavru köpek yaralanıyor, kayboluyor veya terk ediliyor. Elimizdeki kaynaklarla mümkün olduğunca çok hayvana yardım etmek için elimizden gelenin en iyisini yapıyoruz. Kurtarma hikayelerimizden bazılarını burada bulabilirsiniz.",
      lastParagraph:
        "Unutmayin ki, bir köpeği kurtarmak dünyayı değiştirmez ama kesinlikle o köpek için dünya sonsuza kadar değişir.",
    },
    adoption: {
      title: "Sahiplendirme",
      firstParagraph:
        "Ne yazık ki dünyanın pek çok yerinde hayvanlar kendilerine bakacak kimse bulamadan başıboş bırakılıyor veya sahipleri tarafından terk ediliyor. Birçoğu sokaklarda hastalanıyor, yaralanıyor veya tacize uğruyor ve hayatta kalamıyor.",
      lastParagraph:
        "Bir Pati De Sen Tut'un temel amaçlarından biri, sokakta yapamayacak durumdaki hayvanlar için en iyi yuvayı bulmaktır. Onlara hak ettikleri bir hayatı vermek en büyük temennimizdir.",
    },
  };

  return (
    <main>
      <Header />
      <div className="container dogs-container">
        <Link to="/dogs/dogo-pitbull">
          <div className="dogs-column">
            <div className="image-container">
              <img src={dogo} alt="Shelters in Turkey"></img>
            </div>
            <div className="content-container">
              <h1>
                {language.lang === "eng"
                  ? contentENG.dogoPitbull.title
                  : contentTR.dogoPitbull.title}
              </h1>
              <p>
                {language.lang === "eng"
                  ? contentENG.dogoPitbull.firstParagraph
                  : contentTR.dogoPitbull.firstParagraph}
              </p>
              <p>
                {language.lang === "eng"
                  ? contentENG.dogoPitbull.secondParagraph
                  : contentTR.dogoPitbull.secondParagraph}
              </p>
              <p>
                {language.lang === "eng"
                  ? contentENG.dogoPitbull.lastParagraph
                  : contentTR.dogoPitbull.lastParagraph}
              </p>
            </div>
          </div>
        </Link>
        <Link to="/dogs/cases">
          <div className="dogs-column">
            <div className="image-container">
              <img src={cases} alt="How you can help?"></img>
            </div>
            <div className="content-container">
              <h1>{language.lang === "eng" ? contentENG.cases.title : contentTR.cases.title}</h1>
              <p>
                {language.lang === "eng"
                  ? contentENG.cases.firstParagraph
                  : contentTR.cases.firstParagraph}
              </p>
              <p>
                {language.lang === "eng"
                  ? contentENG.cases.lastParagraph
                  : contentTR.cases.lastParagraph}
              </p>
            </div>
          </div>
        </Link>
        <Link to="/dogs/adoption">
          <div className="dogs-column">
            <div className="image-container">
              <img src={adopt} alt="How you can help?"></img>
            </div>
            <div className="content-container">
              <h1>
                {language.lang === "eng" ? contentENG.adoption.title : contentTR.adoption.title}
              </h1>
              <p>
                {language.lang === "eng"
                  ? contentENG.adoption.firstParagraph
                  : contentTR.adoption.firstParagraph}
              </p>
              <p>
                {language.lang === "eng"
                  ? contentENG.adoption.lastParagraph
                  : contentTR.adoption.lastParagraph}
              </p>
            </div>
          </div>
        </Link>
      </div>
      <Footer />
    </main>
  );
};

export default Dogs;
