import Header from "../Header/Header";
import Footer from "../Footer";
import { Context } from "../../App";
import { useContext, useEffect } from "react";

const HelpUsPage = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng"
      ? (document.title = "Privacy Policy")
      : (document.title = "Gizlilik Sozlesmesi");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Our Privacy Policy",
      tr: "Gizlilik Sozlesmesi",
    },
    intro: {
      eng: "The following statement explains our policy regarding the personal information we collect about you.",
      tr: "Aşağıdaki beyan, hakkınızda topladığımız kişisel bilgilere ilişkin politikamızı açıklamaktadır.",
    },
    prph_title_1: {
      eng: "1. Statement of intent",
      tr: "1. Niyet Beyanı",
    },
    prph_1: {
      eng: "From time to time, you may be asked to submit personal information about yourself (eg name and email address etc) in order to receive or use services on our website. Such services include our Contact Form and Application Forms. By entering your details in the fields requested, you enable Hold my paw to provide you with the services or information you select. Whenever you provide such personal information, we will treat the information in accordance with this policy. Our services are designed to give information that you want to receive. Hold my paw will act in accordance with current legislation and aim to meet current Internet best practice.",
      tr: "Zaman zaman, web sitemizdeki hizmetleri almak veya kullanmak için kendinizle ilgili kişisel bilgiler (örneğin, ad ve e-posta adresi vb.) vermeniz istenebilir. Bu tür bilgiler, İletişim Formumuz ve Başvuru Formlarımızda yer almaktadır.İstenen alanlara bilgilerinizi girerek, Bir pati de sen tut’un size seçtiği hizmetleri veya bilgileri sunmasını sağlarsınız. Bu tür kişisel bilgileri verdiğinizde, bilgileri bu politikaya uygun olarak ele alacağız. Hizmetlerimiz, almak istediğiniz bilgileri vermek için tasarlanmıştır. Bir pati de sen tut, mevcut mevzuata uygun hareket edecek ve mevcut İnternet uygulamalarını karşılamayı amaçlayacaktır.",
    },
    prph_title_2: {
      eng: "2. Cookies",
      tr: "2. Çerezler",
    },
    prph_2: {
      eng: "The following is a list of the Cookies placed on your Browser when you visit our website;",
      tr: "Web sitemizi ziyaret ettiğinizde Tarayıcınıza yerleştirilen Çerezlerin bir listesi aşağıdadır;",
    },
    item_1: {
      eng: "Our Own Cookies",
      tr: "Kendi Çerezlerimiz",
    },
    item_2: {
      eng: "Our website, other than the third party cookies listed, below, generates cookies;",
      tr: "Web sitemiz, aşağıda sayılan üçüncü kişi çerezler dışında çerezler oluşturmakta;",
    },
    sub_item_1: {
      eng: "- To help process our Contact Form.",
      tr: "- İletişim Formumuzu işlemeye yardımcı olmak için.",
    },
    sub_item_2: {
      eng: "- To allow donation payments",
      tr: "- Bağış ödemelerine izin vermek.",
    },
    prph_title_3: {
      eng: "3. Submitting personal information",
      tr: "3. Kişisel bilgilerin gönderilmesi",
    },
    prph_3: {
      eng: "When you supply any personal information to Hold my paw, we have legal obligations towards you in the way we deal with that data. We must collect the information fairly, that is, we must explain how we will use it. All information you provide to Hold my paw will only be used within Hold my paw and will never be supplied to anyone outside Hold my paw without first obtaining your consent, unless we are obliged by law to disclose it. We will hold your personal information on our systems for as long as you use the service you have requested, and remove it in the event that the purpose has been met.",
      tr: "Bir pati de sen tuta herhangi bir kişisel bilgi verdiğinizde, bu verileri ele alma şeklimizde size karşı yasal yükümlülüklerimiz vardır. Bilgileri adil bir şekilde toplamalıyız, yani nasıl kullanacağımızı açıklamalıyız. Bir pati de sen tuta sağladığınız tüm bilgiler yalnızca Bir pati de sen tut içinde kullanılacaktır ve kanunen ifşa etmemiz gerekmedikçe, önce onayınızı almadan Bir pati de sen tut dışındaki hiç kimseye asla verilmeyecektir. Kişisel bilgilerinizi, talep ettiğiniz hizmeti kullandığınız sürece sistemlerimizde tutacağız ve amacın gerçekleşmesi durumunda kaldıracağız.",
    },
    prph_title_4: {
      eng: "4. Access to your personal information",
      tr: "4. Kişisel bilgilerinize erişim",
    },
    prph_4: {
      eng: "You have the right to request a copy of the personal information Hold my paw holds about you and to have any inaccuracies corrected.",
      tr: "Bir pati de sen tutın sizin hakkınızda tuttuğu kişisel bilgilerin bir kopyasını talep etme ve herhangi bir yanlışlığın düzeltilmesini isteme hakkına sahipsiniz.",
    },
    prph_title_5: {
      eng: "5. Users under 18",
      tr: "5. 18 yaşından küçük kullanıcılar",
    },
    prph_5: {
      eng: "If your age is under 18, please get your parent/guardian’s permission beforehand whenever you provide personal information to Hold my paw’s website. Users without this consent are not allowed to provide us with personal information.",
      tr: "Yaşınız 18in altındaysa, Bir pati de sen tutun web sitesine kişisel bilgilerinizi her verdiğinizde lütfen önceden ebeveyninizin/velinizin iznini alın. Bu izni olmayan kullanıcıların bize kişisel bilgilerini vermelerine izin verilmez.",
    },
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container background-image">
        <div className="intro">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <p>{language.lang === "eng" ? content.prph_title_1.eng : content.prph_title_1.tr}</p>
          <p>{language.lang === "eng" ? content.prph_1.eng : content.prph_1.tr}</p>
          <p>{language.lang === "eng" ? content.prph_title_2.eng : content.prph_title_2.tr}</p>
          <p>{language.lang === "eng" ? content.prph_2.eng : content.prph_2.tr}</p>
          <p>{language.lang === "eng" ? content.item_1.eng : content.item_1.tr}</p>
          <p>{language.lang === "eng" ? content.item_2.eng : content.item_2.tr}</p>
          <p>{language.lang === "eng" ? content.sub_item_1.eng : content.sub_item_1.tr}</p>
          <p>{language.lang === "eng" ? content.sub_item_2.eng : content.sub_item_2.tr}</p>
          <p>{language.lang === "eng" ? content.prph_title_3.eng : content.prph_title_3.tr}</p>
          <p>{language.lang === "eng" ? content.prph_3.eng : content.prph_3.tr}</p>
          <p>{language.lang === "eng" ? content.prph_title_4.eng : content.prph_title_4.tr}</p>
          <p>{language.lang === "eng" ? content.prph_4.eng : content.prph_4.tr}</p>
          <p>{language.lang === "eng" ? content.prph_title_5.eng : content.prph_title_5.tr}</p>
          <p>{language.lang === "eng" ? content.prph_5.eng : content.prph_5.tr}</p>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default HelpUsPage;
