import Header from "../../Header/Header";
import Footer from "../../Footer";
import AnimalBox from "../../AnimalBox";

import { Context } from "../../../App";
import { useContext, useEffect } from "react";

const Cases = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Dog Cases") : (document.title = "Köpek Vakaları");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Dogs looking for sponsor",
      tr: "Sponsor arayan köpeklerimiz",
    },
    intro: {
      eng: "The following dogs are looking for sponsors. Some of them are fully recovered, some of them are still in treatment. The ones who already recovered stays in pet hotel and looking for forever homes.",
      tr: "Aşağıdaki köpekler sponsor arıyor. Bazıları tamamen iyileşti, bazıları hala tedavi görüyor. İyileşenler ise evcil hayvan otelinde kalıyor ve ömürlük yuva arıyor.",
    },
    sponsorLinkText: {
      eng: "Click for sponsorship",
      tr: "Sponsor olmak icin tıklayın",
    },
  };

  const contentCushionFamily = {
    name: "Cushion Family",
    description1: {
      eng: "We received a tip with a text message that there has been a dog very hungry and having 5 puppies. We went to the address to see their conditions and it was horrible. Mom was all skin and bone and the babies were crying due to the fact that they were all starved. The place that they were trying to live was near a garbage container. We couldn't leave them there and took them to a vet to check-up. We placed them in a pet hotel after the veterinary control. They are good and safe now.",
      tr: "Bir köpeğin çok aç olduğuna ve 5 yavrusu olduğuna dair kısa mesaj içeren bir ihbar aldık. Durumlarını görmek için adrese gittik ve gördüğümüz manzara korkunçtu. Anne bir deri bir kemik kalmıştı ve bebekler açlıktan ölmek üzere oldukları için ağlıyorlardı. Yaşamaya çalıştıkları yer bir çöp konteynırının yanıydı. Onları orada bırakamadık ve kontrol için veterinere götürdük. Veteriner kontrolünden sonra pet otele yerleştirdik. Şimdi iyiler ve güvendeler.",
    },
    description2: {
      eng: "They are currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for permanent homes for babies and mother.",
      tr: "Şu an bir pansiyonda kalıyorlar. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda bebekler ve anne içim kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentMax = {
    name: "Max",
    description1: {
      eng: "Max is a dog that was unfortunately bought and later abandoned by people that Max knows as its owner. When we found him, his fur was in poor condition and he had healed wounds on his body. We understand that he was beaten by other dogs on the street, but somehow his wounds healed. We decided to look for a home for this beautiful dog that could not live on the street and placed it in a foster home. He has been vet checked up, vaccinated and microchipped.",
      tr: "Max maalesef satın alınıp sonrasında ise sahibi bildikleri kişilerce terkedilmiş bir köpek. Onu bulduğumuzda tüyleri kötü durumda ve vücudunda iyileşmiş yaraları vardı. Anladığımız sokakta diğer köpeklerden dayak yemiş ancak bir şekilde yaraları iyileşmişti. Sokakta yaşayamayacak bu güzel köpeğe yuva aramaya karar verdik ve bir geçici eve yerleştirdik. Veteriner kontrolünden geçti, aşıları yapıldı ve çiplendi.",
    },
    description2: {
      eng: "He had a sponsor for his pet hotel and food expenses but he’s been waiting for his (hopefully this time) forever home for almost a year. ",
      tr: "Pansiyon ve mama giderleri için bir sponsoru var ama neredeyse bir yıldır yuvasına gitmeyi bekliyor. Umarım bu sefer gerçek evini bulacak.",
    },
    sponsorStatus: true,
  };

  const contentCorba = {
    name: "Çorba",
    description1: {
      eng: "Çorba lived with his sister in a hospital garden. We noticed that they constantly vomited what they ate or drank. They had scabies, too. Unfortunately we were only able to catch Çorba because they were savage and Çorba was the weak one.  However, two days later we found his sister’s body. She probably suffered from the same disease. Çorba had congenital aortic stenosis, and this made him vomit everything he ate or drank, even water. The only solution was surgery, but we didn't know if he would survive from such an operation. On the other hand, he would starve to death right in front of our eyes, if he didn't have surgery. We took this difficult decision and had him operated. He also chose to fight and now he can eat without vomiting.",
      tr: "Çorba kardeşiyle beraber bir hastane bahçesinde yaşıyordu. Besleme sırasında yediklerini sürekli kustuklarını fark ettik. İleri derece de uyuzları vardı ve kliniğe aldık. Maalesef yabani oldukları için sadece çorbayı yakalayabildik ve iki gun sonra kardeşinin ölüsünü bulduk. Muhtemelen aynı hastalıktan muzdaripti. Çorbada doğuştan aort darlığı vardı ve bu yediği herşeyi suyu bile kusmasina sebep oluyordu. Tek cozumu ameliyattı ama küçük bedeni kaldırırmı bilmiyorduk. Ameliyat olmazsa da gözümüzün  önünde açlıktan ölecekti. Bu zor kararı alıp ameliyat ettirdik. O da savaşmayı tercih etti ve şimdi kusmadan yemek yiyebiliyor.",
    },
    description2: {
      eng: "He needs to first gain weight, then we will make him vaccinated. Currently, he is still at the vet in the post-operative rest period. We are looking for sponsors for veterinary expenses.",
      tr: "Simdi artik once kilo alacak sonra asilari ve daha sonra da yuva arayacagiz. Şuan hala veterinerde ameliyat sonrası istirahat döneminde. Veteriner masrafları için sponsor arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentCello = {
    name: "Çello",
    description1: {
      eng: "Our meeting with Çello was truly due to his crying. It all started with waking up at 5 AM in the morning to the crying of a dog. A tiny puppy all alone, unprotected and incredibly hungry. We took this baby, who had no luck on the streets, to the vet right away. He had a severe external parasite and had an infection. When his treatment was completed we placed him in the pet hotel but after a short time he got distemper. This warrior boy also succeeded in defeating this deadly disease. He is now 1 year old, vaccinated, spayed and microchipped.",
      tr: "Çello ile tanışmamız tamamen onun hayata tutunma çığlığı ile oldu. Sabahın 5inde bir köpeğin çığlıkla karışık ağlamasına uyanmak ile başladı herşey. Küçücük bir köpek yavrusu, tek başına, korunmasız ve inanılmaz acıkmış. Sokaklarda hiç şansı olmayan bu bebeği hemen veterinere götürdük.  Ağır bir dış paraziti ve enfeksiyonu vardı. Tedavisi tamamlanıp onu pansiyona yerleştirmiştik ki gençlik hastalığına yakalandı. Bu savaşçı oğlan tam bu ölümcül hastalığı da yenmeyi başardı. Şu an neredeyse 1 yaşında. Aşıları tamamlandı, kısırlaştırıldı ve çiplendi.",
    },
    description2: {
      eng: "He is currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentKofte = {
    name: "Köfte",
    description1: {
      eng: "Someone left him on one of the feeding spots where we leave food and water for strays. He couldn’t walk when we found him. We immediately took him home and admired how good-natured he was. He slept all night in peace without leaving his kennel. Veterinary examination the next day showed he had a bone fracture on his hind leg, so he immediately had an operation.",
      tr: "Köfte besleme noktalarımızdan birine bırakılmıştı. Onu bulduğumuzda yürüyemiyordu. Hemen koruyucu bir eve yerleştirdik ve ne kadar iyi huylu olduğuna hayran kaldık. Köpek taşıma kutusundan ayrılmadan bütün gece huzur içinde uyudu. Ertesi gün yapılan veteriner muayenesinde arka bacağında kemik kırığı olduğu görüldü, bu nedenle hemen ameliyat oldu.",
    },
    description2: {
      eng: "He’s still recovering boarded on a pet hotel and waiting for his future forever family. He has a sponsor for his pet hotel, food and veterinary expenses.",
      tr: "Şu an bir evcil hayvan otelinde kalıyor ve iyileşme sürecinde. Pansiyon, mama ve veteriner masrafları için sponsoru var ancak kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: true,
  };

  const contentRobin = {
    name: "Robin",
    description1: {
      eng: "Robin was abandoned in a parking lot and badly injured by other dogs in the area. When we heard about her, we immediately took her to the vet. When we went to pick her up, she came running to us despite all the wounds on her body, and when we opened the car door, she got in and did not want to get out of the car. She was treated for a month. She was then vaccinated, neutered and microchipped.",
      tr: "Robin bir otoparka terk edilmiş ve bölgedeki diğer köpekler çok kötü şekilde yaralamıştı. İhbarı geldiğinde ise hemen veterinere aldık. Yanına gittiğimizde vücudundaki tüm yaralara rağmen koşarak yanımıza geldi ve arabanın kapısını açtığımızda komut bile beklemeden hemen arabaya girip çıkmak istemedi. Bir ay tedavi gördü. Sonrasında aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    description2: {
      eng: "She is currently staying in a pet hotel. We are looking for sponsors for pet hotel, food and veterinary expenses. We are also looking for a permanent home.",
      tr: "Şu an bir pansiyonda kalıyor. Pansiyon, mama ve veteriner masrafları için sponsor arıyoruz. Aynı zamanda kalıcı bir yuva arıyoruz.",
    },
    sponsorStatus: false,
  };

  const imagesCorba = [
    { url: require("../../../assets/dogs/dog-cases/corba/corba-1.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/corba/corba-2.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/corba/corba-3.jpeg") },
  ];

  const imagesCello = [
    { url: require("../../../assets/dogs/dog-cases/cello/cello-1.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cello/cello-2.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cello/cello-4.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cello/cello-5.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cello/cello-6.jpeg") },
  ];

  const imagesRobin = [
    { url: require("../../../assets/dogs/dog-cases/robin/robin-1.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/robin/robin-2.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/robin/robin-3.jpeg") },
  ];

  const imagesKofte = [
    { url: require("../../../assets/dogs/dog-cases/kofte/kofte-1.jpg") },
    { url: require("../../../assets/dogs/dog-cases/kofte/kofte-2.jpg") },
    { url: require("../../../assets/dogs/dog-cases/kofte/kofte-3.jpg") },
  ];

  const imagesMax = [
    { url: require("../../../assets/dogs/dog-cases/max/max-1.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-2.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-3.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-4.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-5.png") },
  ];

  const imagesCushionFamily = [
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-1.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-2.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-3.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-4.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-5.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-6.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-7.jpeg") },
    { url: require("../../../assets/dogs/dog-cases/cushionFamily/family-8.jpeg") },
  ];

  return (
    <main>
      <Header />
      <div className="container half-width-container">
        <div className="justify">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <a href="https://form.jotform.com/221033421746951" target="_blank" rel="noreferrer">
            {language.lang === "eng" ? content.sponsorLinkText.eng : content.sponsorLinkText.tr}
          </a>
        </div>
        <AnimalBox images={imagesCorba} content={contentCorba} />
        <AnimalBox images={imagesCello} content={contentCello} />
        <AnimalBox images={imagesRobin} content={contentRobin} />
        <AnimalBox images={imagesCushionFamily} content={contentCushionFamily} />
        <AnimalBox images={imagesKofte} content={contentKofte} />
        <AnimalBox images={imagesMax} content={contentMax} />
      </div>
      <Footer />
    </main>
  );
};

export default Cases;
