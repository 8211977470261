import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../App";

import facebook from "../assets/icons/facebook.png";
import twitter from "../assets/icons/twitter.png";
import instagram from "../assets/icons/instagram.png";
import iyzico_white from "../assets/iyzico/logo_band_white@1X.png";
import iyzico_colored from "../assets/iyzico/logo_band_colored@1X.png";

const Footer = () => {
  const { lang } = useContext(Context);

  const contentENG = {
    home: "Home",
    about: "About",
    helpus: "Help Us",
    cats: "Cats",
    dogs: "Dogs",
    happyTails: "Happy Tails",
    contact: "Contact",
    buyFood: " You can buy pet food:",
    onlineDonation: "You can donate online:",
    bankTransfer: " You can donate us via bank transfer:",
    foodBankLinkText: "Food Bank",
    onlineDonationLinkText: "Online Donation",
    privacyPolicy: "Privacy Policy",
    termsOfUse: "Terms of Use",
  };
  const contentTR = {
    home: "Ana Sayfa",
    about: "Hakkımızda",
    helpus: "Yardım",
    cats: "Kediler",
    dogs: "Köpekler",
    happyTails: "Mutlu Patiler",
    contact: "İletişim",
    buyFood: "Mama satın alabilirsiniz:",
    onlineDonation: "Online bağış yapablirsiniz:",
    bankTransfer: " Banka transferi ile bağış yapabilirsiniz:",
    foodBankLinkText: "Mama Bağışı",
    onlineDonationLinkText: "Online Bağış",
    privacyPolicy: "Gizlilik Sözleşmesi",
    termsOfUse: "Kullanım Şartları",
  };

  return (
    <footer>
      <div className="footer-column">
        <div className="footer-title">
          <Link to="/">{lang === "eng" ? contentENG.home : contentTR.home}</Link>
        </div>
        <div className="footer-title">
          <Link to="/about">{lang === "eng" ? contentENG.about : contentTR.about}</Link>
        </div>
        <div className="footer-title">
          <Link to="/helpus">{lang === "eng" ? contentENG.helpus : contentTR.helpus}</Link>
        </div>
        <div className="footer-title">
          <Link to="/privacyPolicy">
            {lang === "eng" ? contentENG.privacyPolicy : contentTR.privacyPolicy}
          </Link>
        </div>
        <div className="footer-title">
          <Link to="/termsOfUse">
            {lang === "eng" ? contentENG.termsOfUse : contentTR.termsOfUse}
          </Link>
        </div>
      </div>
      <div className="footer-column">
        <div className="footer-title">
          <Link to="/cats">{lang === "eng" ? contentENG.cats : contentTR.cats}</Link>
        </div>
        <div className="footer-title">
          <Link to="/dogs">{lang === "eng" ? contentENG.dogs : contentTR.dogs}</Link>
        </div>
        <div className="footer-title">
          <Link to="/happyTails">
            {lang === "eng" ? contentENG.happyTails : contentTR.happyTails}
          </Link>
        </div>
        <div className="footer-title">
          <Link to="/contact">{lang === "eng" ? contentENG.contact : contentTR.contact}</Link>
        </div>
      </div>
      <div className="footer-column footerSocial">
        <a href="https://www.facebook.com/groups/254271883573280" target="_blank" rel="noreferrer">
          <img src={facebook} alt="facebook"></img>
        </a>
        <a href="https://twitter.com/Bipatidesentut3" target="_blank" rel="noreferrer">
          <img src={twitter} alt="twitter"></img>
        </a>
        <a
          href="https://www.instagram.com/birpatidesentutdernegi/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram"></img>
        </a>
      </div>
      <div className="footer-column">
        <div>{lang === "eng" ? contentENG.buyFood : contentTR.buyFood}</div>
        <div className="footer-title">
          <a href="https://www.ormanamama.com/birpatider" target="_blank" rel="noreferrer">
            {lang === "eng" ? contentENG.foodBankLinkText : contentTR.foodBankLinkText}
          </a>
        </div>
        <div>{lang === "eng" ? contentENG.onlineDonation : contentTR.onlineDonation}</div>
        <div className="footer-title">
          <a href="https://www.patreon.com/birpatidesentut" target="_blank" rel="noreferrer">
            {lang === "eng" ? contentENG.onlineDonationLinkText : contentTR.onlineDonationLinkText}
          </a>
        </div>
        <div className="iyzicoFooterLogo">
          <img src={iyzico_colored} alt="iyzico"></img>
        </div>
      </div>
      <div className="footer-column">
        <div className="footer-title">
          {lang === "eng" ? contentENG.bankTransfer : contentTR.bankTransfer}
        </div>
        <div>TRY IBAN: TR71 0001 0026 7797 5563 4050 01</div>
        <div>USD IBAN: TR17 0001 0026 7797 5563 4050 03</div>
        <div>EUR IBAN: TR87 0001 0026 7797 5563 4050 04</div>
        <div>Swift/BIC: TCZBTR2AXXX</div>
        <div>CHIPS: 409993</div>
      </div>
    </footer>
  );
};

export default Footer;
