import Header from "../Header/Header";
import Footer from "../Footer";
import { Context } from "../../App";
import { useContext, useEffect } from "react";

const AboutPage = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "About") : (document.title = "Hakkımızda");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    pageTitle: {
      eng: "About Us",
      tr: "Hakkımızda",
    },
    intro: {
      eng: "Although stray animals in Turkey are under the responsibility of municipalities, due to insufficient equipment and insufficient human resources, volunteers and associations play an active role in rescue efforts such as treatment, castration and feeding of stray animals. The founders of Bir Pati De Sen Tut worked voluntarily and individually since 2016. To help and rescue more animals, they decided to establish a non-profit organisation. In March 2022, bir pati de sen tut was registered and became a legally approved non-profit organisation.",
      tr: "Türkiyedeki sokak hayvanları belediyelerin sorumluluğunda olsa da yetersiz ekipman ve yetersiz insan kaynağı yüzünden gönüllüler ve dernekler sokak hayvanlarının tedavi, kısırlaştırma ve besleme gibi kurtarma çalışmalarında aktif rol oynamaktadir. Dernek kurucularımız da 2016 senesinden bu yana gönüllü olarak yürüttükleri çalışmaları Mart 2022 tarihinde bir pati de sen tut derneğini kurarak dernek catisi altinda kurtarma çalışmalarına devam etmektedirler. Bir pati de sen tut derneği kayıtlı ve denetime tabi resmi bir dernek olup gönüllülük esasıyla çalışmaktadır.",
    },
    aimTitle: {
      eng: "Our Aim",
      tr: "Amacımız",
    },
    aim: {
      eng: "The aims of the association are to contribute to the control of the stray animal population in Turkey with castration activities, to protect the prohibited breeds, and to find a home for the animals in the streets who are neglected, abused or cannot survive.",
      tr: "Derneğin amaçları Türkiye'deki sokak hayvanları popülasyonunu kısırlaştırma faaliyetleri ile kontrol altında tutulmasına katkıda bulunmak, yasaklı ırkları koruma altına almak, sokaklarda mağdur durumdaki hayvanlara yuva bulmaktır.. Bunun yanı sıra beslenme ve barınma gibi sorunlara da çözüm üretmek ve aktif rol alarak yine sokak hayvanlarının yaşamlarını daha konforlu hale getirmeye çalışmaktadır.",
    },
    helpTitle: {
      eng: "Help Us",
      tr: "Yardım",
    },
    help: {
      eng: "If you want to help us, you can donate, become a foster family, or adopt a cat or dog. You can find detailed information on 'Help Us' page.",
      tr: "Bir pati de siz tutmak isterseniz bize bağış yapabilir, koruyucu aile olabilir, kedi ya da köpek sahiplenebilirsiniz. Nasıl yardımcı olabileceğinize dair detaylı bilgiye 'Yardım' sayfamızdan ulaşabilirsiniz.",
    },
    thanksTitle: {
      eng: "Thank You",
      tr: "Teşekkürler",
    },
    thanks: {
      eng: "Thank you to all our supporters and everyone who gives these animals a chance.",
      tr: "Tüm destekçilerimize ve bu hayvanlara bir sans veren herkese teşekkür ederiz.",
    },
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container background-image">
        <div className="intro">
          <h1>{language.lang === "eng" ? content.pageTitle.eng : content.pageTitle.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <h2>{language.lang === "eng" ? content.aimTitle.eng : content.aimTitle.tr}</h2>
          <p>{language.lang === "eng" ? content.aim.eng : content.aim.tr}</p>
          <h2>{language.lang === "eng" ? content.helpTitle.eng : content.helpTitle.tr}</h2>
          <p>{language.lang === "eng" ? content.help.eng : content.help.tr}</p>
          <h2>{language.lang === "eng" ? content.thanksTitle.eng : content.thanksTitle.tr}</h2>
          <p>{language.lang === "eng" ? content.thanks.eng : content.thanks.tr}</p>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default AboutPage;
