import { Context } from "../App";
import { useState, useContext } from "react";
import Modal from "react-modal";
import SimpleImageSlider from "react-simple-image-slider";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    width: "80%",
    height: "60%",
    padding: "20px 20px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(213, 209, 233)",
    border: "1px solid #2b2f38",
    borderRadius: "15px",
    zIndex: "1000",
    overflowY: "auto",
  },
};
const AnimalBoxAdoption = ({ images, content }) => {
  const language = useContext(Context);

  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const PageContent = {
    linktext: {
      eng: "See details",
      tr: "Detaylar",
    },
  };
  return (
    <div className="animal-box-container">
      <div className="animal-box">
        <div className="content">
          <h2>{content.name}</h2>
          <p>{language.lang === "eng" ? content.description1.eng : content.description1.tr}</p>
          <div className="animal-button-container">
            <button onClick={openModal} className="animal-button">
              {language.lang === "eng" ? PageContent.linktext.eng : PageContent.linktext.tr}
            </button>
          </div>

          <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
            <h2>{language.lang === "eng" ? content.title1.eng : content.title1.tr}</h2>
            <p>{language.lang === "eng" ? content.story.eng : content.story.tr}</p>
            <h2>{language.lang === "eng" ? content.title2.eng : content.title2.tr}</h2>
            <p>{language.lang === "eng" ? content.gender.eng : content.gender.tr}</p>
            <p>{language.lang === "eng" ? content.birthday.eng : content.birthday.tr}</p>
            <p>{language.lang === "eng" ? content.breed.eng : content.breed.tr}</p>
            <p>{language.lang === "eng" ? content.spay.eng : content.spay.tr}</p>
            <p>{language.lang === "eng" ? content.specialNeeds.eng : content.specialNeeds.tr}</p>
            <p>{language.lang === "eng" ? content.microchipped.eng : content.microchipped.tr}</p>
            <p>{language.lang === "eng" ? content.vaccinated.eng : content.vaccinated.tr}</p>
            <p>{language.lang === "eng" ? content.goodWithKids.eng : content.goodWithKids.tr}</p>
            <p>{language.lang === "eng" ? content.goodWithDogs.eng : content.goodWithDogs.tr}</p>
            <p>{language.lang === "eng" ? content.goodWithCats.eng : content.goodWithCats.tr}</p>
          </Modal>
        </div>
        <div className="images">
          <SimpleImageSlider
            width={250}
            height={250}
            images={images}
            showBullets={true}
            showNavs={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AnimalBoxAdoption;
