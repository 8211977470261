import Header from "../Header/Header";
import Footer from "../Footer";
import { Context } from "../../App";
import { useContext, useEffect } from "react";

const HelpUsPage = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng"
      ? (document.title = "Terms Of Use")
      : (document.title = "Kullanim sartlari");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Terms Of Use",
      tr: "Kullanim sartlari",
    },
    intro: {
      eng: "Thank you for visiting Hold my pawcCharity website. Please make sure you have read and understand these terms and conditions (“Terms”) carefully before using our websites, using social media channels we operate, donating to us or undertaking other activities.",
      tr: "Bir pati de sen tut web sitesini ziyaret ettiğiniz için teşekkür ederiz. Web sitelerimizi kullanmadan, işlettiğimiz sosyal medya kanallarını kullanmadan, bize bağışta bulunmadan veya başka faaliyetlerde bulunmadan önce lütfen bu hüküm ve koşulları (“Şartlar”) okuyup anladığınızdan emin olun.",
    },
    prph_1: {
      eng: " If you are under 18, please get a parent or guardian to read through these Terms and to discuss them with you.",
      tr: " 18 yaşından küçükseniz, lütfen bir ebeveyn veya velinizden bu Şartları okumasını ve sizinle tartışmasını isteyin.",
    },
    prph_2: {
      eng: "These Terms also incorporate the following privacy and policies which apply to your use of our websites and social media sites we manage and maintain:",
      tr: "Bu Koşullar ayrıca yönettiğimiz ve sürdürdüğümüz web sitelerimizi ve sosyal medya sitelerini kullanımınız için geçerli olan aşağıdaki gizlilik ve politikaları içerir:",
    },
    prph_3: {
      eng: "Our Privacy Policy which sets out how we use the information you share with us, and what measures we take to protect it",
      tr: "Bizimle paylaştığınız bilgileri nasıl kullandığımızı ve bunları korumak için hangi önlemleri aldığımızı belirten Gizlilik Politikamız sayfasinda bulabilirsiniz.",
    },
  };

  return (
    <main>
      <Header />

      <div className="container half-width-container background-image">
        <div className="intro">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <p>{language.lang === "eng" ? content.prph_1.eng : content.prph_1.tr}</p>
          <p>{language.lang === "eng" ? content.prph_2.eng : content.prph_2.tr}</p>
          <p>{language.lang === "eng" ? content.prph_3.eng : content.prph_3.tr}</p>
        </div>
      </div>

      <Footer />
    </main>
  );
};

export default HelpUsPage;
