import Header from "../Header/Header";
import Footer from "../Footer";
import { Context } from "../../App";
import { useContext, useEffect } from "react";

const HelpUsPage = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Help Us") : (document.title = "Yardım");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "How can you help us?",
      tr: "Bize nasıl yardım edebilirsiniz?",
    },
    intro: {
      eng: "First of all, we would like to express our gratitude and extreme thanks to everyone who helps in saving the lives of these deserving pets and giving them a chance for a happy future. All of the pets rescued are seen by a veterinarian to have all their vetting needs taken care of and are then placed in a pet hotel or a foster home. We need mostly monetary donations to pay vet, pet transfers and food expenses. Donations, in any amount, are always appreciated and go directly to Bir Pati De Sen Tut Fund for pets who have been neglected, abused, and abandoned across Turkey. When you make a donation you are directly impacting the lives of these poor, homeless animals.",
      tr: "Her şeyden önce, bu hayvanların hayatlarını kurtarmaya yardımcı olan ve onlara mutlu bir gelecek için şans veren herkese minnettarlığımızı ve sonsuz teşekkürlerimizi ifade etmek isteriz. Kurtarılan tüm evcil hayvanlar tüm veterinerlik ihtiyaçlarının karşılanması için veteriner kontrolünden geçiyor ve daha sonra bir evcil hayvan oteline veya koruyucu bir eve yerleştiriliyor. Veteriner, evcil hayvan transferleri ve yemek masraflarını ödemek için çoğunlukla parasal bağışlara ihtiyacımız var. Bağışlanan miktarın önemi olmamakla beraber bağışlarınız bizim için çok kıymetlidir. Gelen bağışlar Bir Pati De Sen Tut Derneği fonuna gönderilerek doğrudan Türkiye genelinde ihmal edilen, istismar edilen ve terk edilen evcil hayvanlar için kullanılmaktadır. Bağış yaptığınızda, bu zavallı, evsiz hayvanların hayatlarını doğrudan etkilemiş oluyorsunuz.",
    },
    bankTransferIntro: {
      eng: "You can donate to us by transferring money to our following bank accounts or through our donation site. ",
      tr: "Aşağıdaki banka hesaplarına para transferi yaparak ya da doğrudan aşağıdaki bağış linkimiz üzerinden bağış yapabilirsiniz.",
    },
    bankTransferTitle: {
      eng: "Bank Account Details",
      tr: "Banka Hesap Bilgileri",
    },
    accountHolderDetails: {
      eng: "Account Holder Name: Bir pati de sen tut dernegi",
      tr: "Alıcı Adı: Bir pati de sen tut derneği",
    },
    foodBankIntro: {
      eng: "We also have a food-bank where you can buy cat or dog food for our rescued animals. For shippıng details, please enter only ‘Bir Pati De Sen Tut’. This would be enough for us to receive the ordered food.",
      tr: "Aşağıdaki kumbara linkimiz üzerinden kurtardığımız hayvanlar için mama siparişinde bulunabilirsiniz. Adres bilgisine ‘Bir Pati De Sen Tut’ yazmaniz yeterlidir.",
    },
    foodBankLinkText: {
      eng: "Food Bank",
      tr: "Mama Bağışı",
    },
    onlineDonationLinkText: {
      eng: "Online Donation",
      tr: "Online Bağış",
    },
    needsTitle: {
      eng: "We are also in need of:",
      tr: "Ayrıca şunlara da ihtiyacımız var:",
    },
    neededItems: {
      eng: {
        item1: "Crates and Plastic Carriers (any usable condition)",
        item2: "Leashes and Collars (all sizes)",
        item3: "Food Dishes",
        item4: "Training Treats and Biscuits",
        item5: "Puppy Pee Pads and Poop Bags",
        item6: "Cat Litter",
        item7: "Pet Toys",
        item8: "Brushes and Other Grooming Supplies",
        item9: "Cleaning Products",
        item10: "Paper Towels",
      },
      tr: {
        item1: "Kafes ve taşıma çantası (Kullanılabilir durumda)",
        item2: "Tasmalar ve kayışlar (Tüm bedenler)",
        item3: "Yemek Tabakları",
        item4: "Köpek eğitim malzemeleri",
        item5: "Köpek Çiş Pedleri ve Kaka Torbaları",
        item6: "Kedi kumu",
        item7: "Oyuncaklar",
        item8: "Tarama fırçaları ve Diğer Bakım Malzemeleri",
        item9: "Temizlik ürünleri",
        item10: "Kağıt havlu",
      },
    },
    buttonText1: {
      eng: 'TRY 100',
      tr: '100 TL'
    },
    buttonText2: {
      eng: 'TRY 250',
      tr: '250 TL'
    },
    buttonText3: {
      eng: 'TRY 500',
      tr: '500 TL'
    },
    buttonText4: {
      eng: 'TRY 1000',
      tr: '1000 TL'
    },
    titleOneTime: {
      eng: 'One Time Donation',
      tr: 'Tek Seferlik Bağış'
    },
    introOneTime: {
      eng: 'We can buy petfood, pay vet expences and many more with your one time donation.',
      tr: 'Yapacağınız tek seferlik bağış ile mama alabiliriz, veteriner masraflarını ödeyebiliriz ve daha fazlasını yapabiliriz.'
    }
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container background-image">
        <div className="intro">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <p>
            {language.lang === "eng" ? content.bankTransferIntro.eng : content.bankTransferIntro.tr}
          </p>
          <p>
            <button className="donation-button">
              <a href="https://www.patreon.com/birpatidesentut" target="_blank" rel="noreferrer">
                {language.lang === "eng"
                  ? content.onlineDonationLinkText.eng
                  : content.onlineDonationLinkText.tr}
              </a>
            </button>
          </p>
          <br />
          <h2>
            {language.lang === "eng" ? content.bankTransferTitle.eng : content.bankTransferTitle.tr}
          </h2>

          <p>
            {language.lang === "eng"
              ? content.accountHolderDetails.eng
              : content.accountHolderDetails.tr}
          </p>
          <p>TRY IBAN: TR71 0001 0026 7797 5563 4050 01</p>
          <p>USD IBAN: TR17 0001 0026 7797 5563 4050 03</p>
          <p>EUR IBAN: TR87 0001 0026 7797 5563 4050 04</p>
          <p>Swift/BIC: TCZBTR2AXXX</p>
          <p>CHIPS: 409993</p>
        </div>
        <div className="oneTimeDonationContainer">
          <h1>{language.lang === "eng" ? content.titleOneTime.eng : content.titleOneTime.tr}</h1>
          <p>{language.lang === "eng" ? content.introOneTime.eng : content.introOneTime.tr}</p>
          <div className="donationContainer">
            <button className="donation-button">
              <a href="https://iyzi.link/AI_c3Q" target="_blank" rel="noopener noreferrer">
                {language.lang === "eng" ? content.buttonText1.eng : content.buttonText1.tr}
              </a>
            </button>
            <button className="donation-button">
              <a
                href="https://iyzi.link/AI_c3g"
                target="_blank"
                rel="noopener noreferrer"
              >
                {language.lang === "eng" ? content.buttonText2.eng : content.buttonText2.tr}
              </a>
            </button>
            <button className="donation-button">
              <a
                href="https://iyzi.link/AI_c3w"
                target="_blank"
                rel="noopener noreferrer"
              >
                {language.lang === "eng" ? content.buttonText3.eng : content.buttonText3.tr}
              </a>
            </button>
            <button className="donation-button">
              <a
                href="https://iyzi.link/AI_c4A"
                target="_blank"
                rel="noopener noreferrer"
              >
                {language.lang === "eng" ? content.buttonText4.eng : content.buttonText4.tr}
              </a>
            </button>
          </div>
        </div>
        <div className="intro">
          <p>{language.lang === "eng" ? content.foodBankIntro.eng : content.foodBankIntro.tr}</p>
          <button className="donation-button">
            <a href="https://www.ormanamama.com/barbunya-sovalyesi-ve-patileri" target="_blank" rel="noreferrer">
              {language.lang === "eng" ? content.foodBankLinkText.eng : content.foodBankLinkText.tr}
            </a>
          </button>
        </div>
        <div className="intro">
          <h2>{language.lang === "eng" ? content.needsTitle.eng : content.needsTitle.tr}</h2>
          {Object.values(
            language.lang === "eng" ? content.neededItems.eng : content.neededItems.tr
          ).map((item, id) => {
            return <li key={id}>{item}</li>;
          })}
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default HelpUsPage;
