import Header from "../../Header/Header";
import Footer from "../../Footer";
import AnimalBoxAdoption from "../../AnimalBoxAdoption";

import { Context } from "../../../App";
import { useContext, useEffect } from "react";

const Adoption = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng"
      ? (document.title = "Dog Adoption")
      : (document.title = "Köpek sahiplendirme");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Our fury friends looking for homes...",
      tr: "Sahiplerini bekleyen tüylü dostlarımız...",
    },
    intro: {
      eng: "We place our rescued animals in pet hotels or in foster homes. The following dogs were rescued from bad conditions and are looking for their forever homes.",
      tr: "Kurtarılan hayvanlarımızı evcil hayvan otellerine veya koruyucu evlere yerleştiriyoruz. Aşağıdaki köpekler kötü koşullardan kurtarıldı ve ömürlük yuvalarını arıyorlar.",
    },
    sponsorLinkText: {
      eng: "Click for adoption",
      tr: "Sahiplenmek icin tıklayın",
    },
  };

  /* Copy this content and create a new adoption case */
  const contentMax = {
    name: "Max",
    description1: {
      eng: "Max is a dog that was unfortunately bought and later abandoned by people that Max knows as his family. When we found him, his fur was in poor condition and he had healed wounds on his body. We understood that he had been beaten by other dogs on the street, but somehow his wounds healed. We decided to look for a home for this beautiful dog that could not live on the street and placed him in a pet hotel. He has been vet checked up, vaccinated and microchipped.",
      tr: "Max maalesef satın alınıp sonrasında ise sahibi bildikleri kişilerce terkedilmiş bir köpek. Onu bulduğumuzda tüyleri kötü durumda ve vücudunda iyileşmiş yaraları vardı. Anladığımız sokakta diğer köpeklerden dayak yemiş ancak bir şekilde yaraları iyileşmişti. Sokakta yaşayamayacak bu güzel köpeğe yuva aramaya karar verdik ve bir pansiyona yerleştirdik. Veteriner kontrolünden geçti, aşıları yapıldı ve çiplendi.",
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "Max is a dog that was unfortunately bought and later abandoned by people that Max knows as his family. When we found him, his fur was in poor condition and he had healed wounds on his body. We understood that he had been beaten by other dogs on the street, but somehow his wounds healed. We decided to look for a home for this beautiful dog that could not live on the street and placed him in a pet hotel. He has been vet checked up, vaccinated and microchipped.",
      tr: "Max maalesef satın alınıp sonrasında ise sahibi bildikleri kişilerce terkedilmiş bir köpek. Onu bulduğumuzda tüyleri kötü durumda ve vücudunda iyileşmiş yaraları vardı. Anladığımız sokakta diğer köpeklerden dayak yemiş ancak bir şekilde yaraları iyileşmişti. Sokakta yaşayamayacak bu güzel köpeğe yuva aramaya karar verdik ve bir pansiyona yerleştirdik. Veteriner kontrolünden geçti, aşıları yapıldı ve çiplendi.",
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Male",
      tr: "Cinsiyet: Erkek",
    },
    birthday: {
      eng: "Birthday: 8/5/2021",
      tr: "Doğum günü: 8/5/2021",
    },
    breed: {
      eng: "Breed: German Sheperd Mixed",
      tr: "Irk: Alman Çoban Kırması",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Yes",
      tr: "Çocuklarla arası iyi: Evet",
    },
    goodWithDogs: {
      eng: "Good with dogs: Yes",
      tr: "Köpeklerle arası iyi: Evet",
    },
    goodWithCats: {
      eng: "Good with cats: Yes",
      tr: "Kedilerle arası iyi: Evet",
    },
  };

  const contentRoss = {
    name: "Ross",
    description1: {
      eng: "We found him at a shelter.  He wagged his tail and showed his belly to everyone. He probably had a family before but they abandoned him. We don't know how he ended up at the shelter but he was so sweet that we couldn't leave him there.  We got him out of the shelter, neutered, vaccinated and chipped.",
      tr: "Ross barınağa terk edilmişti. Kuyruğunu sallayıp herkese göbeğini açıyordu. Muhtemelen daha önce bir ailesi vardı ama onu terk ettiler. Barınağa nasıl geldiğini bilmiyoruz ama o kadar tatlıydı ki onu orada bırakamazdık. Barınaktan çıkardık, kısırlaştırdık,aşılattık ve çiplettik.",
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "We found him at a shelter.  He wagged his tail and showed his belly to everyone. He probably had a family before but they abandoned him. We don't know how he ended up at the shelter but he was so sweet that we couldn't leave him there.  We got him out of the shelter, neutered, vaccinated and chipped.",
      tr: "Ross barınağa terk edilmişti. Kuyruğunu sallayıp herkese göbeğini açıyordu. Muhtemelen daha önce bir ailesi vardı ama onu terk ettiler. Barınağa nasıl geldiğini bilmiyoruz ama o kadar tatlıydı ki onu orada bırakamazdık. Barınaktan çıkardık, kısırlaştırdık,aşılattık ve çiplettik.",
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Male",
      tr: "Cinsiyet: Erkek",
    },
    birthday: {
      eng: "Birthday: 02/02/2021",
      tr: "Doğum günü: 02/02/2021",
    },
    breed: {
      eng: "Breed: American Pit Bull Terrier",
      tr: "Irk: Pitbull",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Unknown",
      tr: "Çocuklarla arası iyi: Bilinmiyor",
    },
    goodWithDogs: {
      eng: "Good with dogs: Unknown",
      tr: "Köpeklerle arası iyi: Bilinmiyor",
    },
    goodWithCats: {
      eng: "Good with cats: Unknown",
      tr: "Kedilerle arası iyi: Bilinmiyor",
    },
  };

  const contentAtlas = {
    name: "Atlas",
    description1: {
      eng: "Atlas was abandoned to a park in a kind of way tied with a strap. His situation became a top trend on social media in Turkey but, but no one saved him. He was still in the same situation as we saw him on social media ads when we went to pick him  up. We took him to the vet right away because he had injuries on his body. Probably he was used in illegal dog fights. Then we spayed and microchipped. Unfortunately, he caught canine distemper after a short time. He was a fighter and recovered from the illness within a month.",
      tr: "Atlas kayışıyla bir çocuk bahçesinin parkına bağlanıp terkedilmişti. Bu hali sosyal medyada gündem olmuş ancak kimse kurtarmamıştı. Almaya gittiğimizde hala aynı şekilde parkta duruyordu. Hemen alıp veterinere götürdük ve vücudundaki yaralardan dövüşlerde kullanıldığını çıkardık. Ardından kısırlaştırıp çiplettik. Maalesef kısa süre sonra gençlik hastalığına yakalandı ancak bır ay gibi bir sürede bu hastalığı da yendi.",
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "Atlas was abandoned to a park in a kind of way tied with a strap. His situation became a top trend on social media in Turkey but, but no one saved him. He was still in the same situation as we saw him on social media ads when we went to pick him  up. We took him to the vet right away because he had injuries on his body. Probably he was used in illegal dog fights. Then we spayed and microchipped. Unfortunately, he caught canine distemper after a short time/ He was a fighter and recovered from the illness within a month.",
      tr: "Atlas kayışıyla bir çocuk bahçesinin parkına bağlanıp terkedilmişti. Bu hali sosyal medyada gündem olmuş ancak kimse kurtarmamıştı. Almaya gittiğimizde hala aynı şekilde parkta duruyordu. Hemen alıp veterinere götürdük ve vücudundaki yaralardan dövüşlerde kullanıldığını çıkardık. Ardından kısırlaştırıp çiplettik. Maalesef kısa süre sonra gençlik hastalığına yakalandı ancak bır ay gibi bir sürede bu hastalığı da yendi.",
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Male",
      tr: "Cinsiyet: Erkek",
    },
    birthday: {
      eng: "Birthday: 02/03/2021",
      tr: "Doğum günü: 02/03/2021",
    },
    breed: {
      eng: "Breed: American Pit Bull Terrier",
      tr: "Irk: Pitbull",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Unknown",
      tr: "Çocuklarla arası iyi: Bilinmiyor",
    },
    goodWithDogs: {
      eng: "Good with dogs: Unknown",
      tr: "Köpeklerle arası iyi: Bilinmiyor",
    },
    goodWithCats: {
      eng: "Good with cats: Unknown",
      tr: "Kedilerle arası iyi: Bilinmiyor",
    },
  };

  const contentKuzey = {
    name: "Kuzey",
    description1: {
      eng: "We saw Kuzey’s ads on social media. He was tied up next to a public toilet and mouldy bread was placed in front of him to eat. There wasn't even water. We immediately went to the place where he was and took him from there for veterinary control. He had scabies in his ears and anaemia. He was treated and subsequently vaccinated, spayed and microchipped.",
      tr: "Kuzeyin ilanini sosyal medyada gördük. Bir umumi tuvaletin yanına bağlanmış ve önüne küflü ekmek konmuştu yemesi için. Su bile yoktu. Hemen bulunduğu yere gidip veteriner kontrolu için oradan aldık. Kulaklarında uyuz ve kansızlık vardı. Tedavi gördü ve sonrasında aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "We saw Kuzey’s ads on social media. He was tied up next to a public toilet and mouldy bread was placed in front of him to eat. There wasn't even water. We immediately went to the place where he was and took him from there for veterinary control. He had scabies in his ears and anaemia. He was treated and subsequently vaccinated, spayed and microchipped.",
      tr: "Kuzeyin ilanini sosyal medyada gördük. Bir umumi tuvaletin yanına bağlanmış ve önüne küflü ekmek konmuştu yemesi için. Su bile yoktu. Hemen bulunduğu yere gidip veteriner kontrolu için oradan aldık. Kulaklarında uyuz ve kansızlık vardı. Tedavi gördü ve sonrasında aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Male",
      tr: "Cinsiyet: Erkek",
    },
    birthday: {
      eng: "Birthday: 12/12/2018",
      tr: "Doğum günü: 12/12/2018",
    },
    breed: {
      eng: "Breed: Dogo Argentino",
      tr: "Irk: Dogo Argentino",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Unknown",
      tr: "Çocuklarla arası iyi: Bilinmiyor",
    },
    goodWithDogs: {
      eng: "Good with dogs: Yes",
      tr: "Köpeklerle arası iyi: Yes",
    },
    goodWithCats: {
      eng: "Good with cats: Unknown",
      tr: "Kedilerle arası iyi: Bilinmiyor",
    },
  };

  const contentToros = {
    name: "Toros",
    description1: {
      eng: "Toros was abandoned on a mountain and badly attacked by the dogs in the area where he was left. When we heard his news,  we immediately went and took him from there, and when we saw him, both his ears were cut off already and his bones were counted. He was taken to vet for checkup and fortunately, he only had anaemia due to starvation. He was treated and subsequently vaccinated, spayed and microchipped.",
      tr: "Toros bir dağ başına atılmış ve atıldığı bölgedeki köpekler tarafından çok kötü saldırıya uğramıştı. İhbarı geldiğinde hemen gidip oradan aldık ve gördüğümüzde hem kulakları kesilmiş hem de kemikleri sayılıyordu. Veteriner kontrolünden geçti ve şanslıydık ki aç kalmasına bağlı olarak sadece kansızlığı vardı. Tedavi gördü ve sonrasında aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "Toros was abandoned on a mountain and badly attacked by the dogs in the area where he was left. When we heard his news,  we immediately went and took him from there, and when we saw him, both his ears were cut off already and his bones were counted. He was taken to vet for checkup and fortunately, he only had anaemia due to starvation. He was treated and subsequently vaccinated, spayed and microchipped.",
      tr: "Toros bir dağ başına atılmış ve atıldığı bölgedeki köpekler tarafından çok kötü saldırıya uğramıştı. İhbarı geldiğinde hemen gidip oradan aldık ve gördüğümüzde hem kulakları kesilmiş hem de kemikleri sayılıyordu. Veteriner kontrolünden geçti ve şanslıydık ki aç kalmasına bağlı olarak sadece kansızlığı vardı. Tedavi gördü ve sonrasında aşıları yapıldı, kısırlaştırıldı ve çiplendi.",
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Male",
      tr: "Cinsiyet: Erkek",
    },
    birthday: {
      eng: "Birthday: 01/12/2020",
      tr: "Doğum günü: 01/12/2020",
    },
    breed: {
      eng: "Breed: Dogo Argentino",
      tr: "Irk: Dogo Argentino",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Unknown",
      tr: "Çocuklarla arası iyi: Bilinmiyor",
    },
    goodWithDogs: {
      eng: "Good with dogs: Yes",
      tr: "Köpeklerle arası iyi: Yes",
    },
    goodWithCats: {
      eng: "Good with cats: Unknown",
      tr: "Kedilerle arası iyi: Bilinmiyor",
    },
  };

  /* Copy this content and create a new adoption case */
  const imagesMax = [
    { url: require("../../../assets/dogs/dog-cases/max/max-1.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-2.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-3.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-4.png") },
    { url: require("../../../assets/dogs/dog-cases/max/max-5.png") },
  ];

  const imagesKuzey = [
    { url: require("../../../assets/dogs/dogo-pitbull/kuzey/kuzey-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kuzey/kuzey-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/kuzey/kuzey-3.jpeg") },
  ];

  const imagesToros = [
    { url: require("../../../assets/dogs/dogo-pitbull/toros/toros-1.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/toros/toros-2.jpeg") },
    { url: require("../../../assets/dogs/dogo-pitbull/toros/toros-3.jpeg") },
  ];

  const imagesRoss = [
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-1.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-2.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-3.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-4.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/ross/ross-5.jpg") },
  ];

  const imagesAtlas = [
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-1.png") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-1.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-2.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-3.jpg") },
    { url: require("../../../assets/dogs/dogo-pitbull/atlas/atlas-4.jpg") },
  ];

  return (
    <main>
      <Header />
      <div className="container half-width-container">
        <div className="justify">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <a href="https://form.jotform.com/220504169691052" target="_blank" rel="noreferrer">
            {language.lang === "eng" ? content.sponsorLinkText.eng : content.sponsorLinkText.tr}
          </a>
        </div>
        <AnimalBoxAdoption images={imagesMax} content={contentMax} />
        <AnimalBoxAdoption images={imagesAtlas} content={contentAtlas} />
        <AnimalBoxAdoption images={imagesRoss} content={contentRoss} />
        <AnimalBoxAdoption images={imagesToros} content={contentToros} />
        <AnimalBoxAdoption images={imagesKuzey} content={contentKuzey} />
      </div>
      <Footer />
    </main>
  );
};

export default Adoption;
