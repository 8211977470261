import { Context } from "../../App";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import cases from "../../assets/cats/cases.jpg";
import adopt from "../../assets/cats/adoption.jpg";
import Header from "../Header/Header";
import Footer from "../Footer";

const Cats = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Cats") : (document.title = "Kediler");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    cases: {
      title: {
        eng: "Cases",
        tr: "Vakalar",
      },
      intro: {
        eng: "Cats can't take care of themselves if they are injured or become sick. Little kittens can't take care of themselves at all. Thousands of cats and kittens are hurt, lost, or abandoned everyday in the world. We do our best to help as many as we possibly can with the resources we have. You can find some of many rescue stories here.",
        tr: "Kediler yaralanırlarsa veya hastalanırlarsa kendilerine bakamazlar. Yavru kedicikler kendilerine hiç bakamazlar. Dünyada her gün binlerce yetişkin kedi ve yavru kedi yaralanıyor, kayboluyor veya terk ediliyor. Elimizdeki kaynaklarla mümkün olduğunca çok hayvana yardım etmek için elimizden gelenin en iyisini yapıyoruz. Kurtarma hikayelerimizden bazılarını burada bulabilirsiniz.",
      },
      quote: {
        eng: "Don’t forget, saving one cat will not change the world, but surely for that one cat, the world will change forever.",
        tr: "Unutmayin ki, bir kediyi kurtarmak dünyayı değiştirmez ama kesinlikle o kedi için dünya sonsuza kadar değişir.",
      },
    },
    adoption: {
      title: {
        eng: "Adoption",
        tr: "Sahiplendirme",
      },
      intro: {
        eng: "Unfortunately, in many parts of the world animals are stray with no one to take care of them or are being abandoned by their owners. Many of them become ill, injure or suffer abuses on the streets and require doctors, medicine, surgeries, and cannot  survive.",
        tr: "Ne yazık ki dünyanın pek çok yerinde hayvanlar kendilerine bakacak kimse bulamadan başıboş bırakılıyor veya sahipleri tarafından terk ediliyor. Birçoğu sokaklarda hastalanıyor, yaralanıyor veya tacize uğruyor ve hayatta kalamıyor.",
      },
      finalSentence: {
        eng: "One of the main goals of Bir Pati De Sen Tut is to find a best home for strays who cannot survive. Our motivation is to give them a life they deserve. You can find available cats to be adopted here.",
        tr: "Bir Pati De Sen Tut'un temel amaçlarından biri, sokakta yapamayacak durumdaki hayvanlar için en iyi yuvayı bulmaktır. Onlara hak ettikleri bir hayatı vermek en büyük temennimizdir.",
      },
    },
  };

  return (
    <main>
      <Header />
      <div className="container dogs-container">
        <Link to="/cats/cases">
          <div className="dogs-column">
            <div className="image-container">
              <img src={cases} alt="How you can help?"></img>
            </div>
            <div className="content-container">
              <h1>{language.lang === "eng" ? content.cases.title.eng : content.cases.title.tr}</h1>
              <p>{language.lang === "eng" ? content.cases.intro.eng : content.cases.intro.tr}</p>
              <p>{language.lang === "eng" ? content.cases.quote.eng : content.cases.quote.tr}</p>
            </div>
          </div>
        </Link>
        <Link to="/cats/adoption">
          <div className="dogs-column">
            <div className="image-container">
              <img src={adopt} alt="How you can help?"></img>
            </div>
            <div className="content-container">
              <h1>
                {language.lang === "eng" ? content.adoption.title.eng : content.adoption.title.tr}
              </h1>
              <p>
                {language.lang === "eng" ? content.adoption.intro.eng : content.adoption.intro.tr}
              </p>
              <p>
                {language.lang === "eng"
                  ? content.adoption.finalSentence.eng
                  : content.adoption.finalSentence.tr}
              </p>
            </div>
          </div>
        </Link>
      </div>
      <Footer />
    </main>
  );
};

export default Cats;
