import Header from "../Header/Header";
import Footer from "../Footer";
import cat from "../../assets/NotFound/cat.png";
import { Context } from "../../App";
import { useContext, useEffect } from "react";

const NotFound = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng"
      ? (document.title = "Page Not Found")
      : (document.title = "Sayfa Bulunamadı");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentENG = "Sorry. The page you are looking for is not here.";
  const contentTR = "Üzgünüz. Erişmek istediğiniz sayfa burada değil.";
  return (
    <main>
      <Header />
      <div className="container NotFound">
        <div className="left">
          <img src={cat} alt="404 not found cat"></img>
        </div>
        <div className="right">
          <h1>404</h1>
          <p>{language.lang === "eng" ? contentENG : contentTR}</p>
        </div>
        <p> </p>
      </div>
      <Footer />
    </main>
  );
};

export default NotFound;
