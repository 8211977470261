import Header from "../../Header/Header";
import Footer from "../../Footer";
import AnimalBox from "../../AnimalBox";
import { Context } from "../../../App";
import { useContext, useEffect } from "react";

const CatsCases = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Cats Cases") : (document.title = "Kedi Vakaları");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Cats Looking For Sponsor",
      tr: "Sponsor Arayan Kedilerimiz",
    },
    intro: {
      eng: "The following cats are looking for sponsors. Some of them are fully recovered, some of them are still in treatment. The ones who already recovered stay in pet hotel and look for forever homes.",
      tr: "Aşağıdaki kediler sponsor arıyor. Bazıları tamamen iyileşti, bazıları hala tedavi görüyor. İyileşenler ise evcil hayvan otelinde kalıyor ve ömürlük yuva arıyor.",
    },
    sponsorLinkText: {
      eng: "Click for sponsorship",
      tr: "Sponsor olmak icin tıklayın",
    },
  };

  const contentAngel = {
    name: "Angel",
    description1: {
      eng: "No one noticed when Angel’s tail got caught in a door. When someone finally found her, her tail was already badly damaged, so had to be amputated. We don’t know for how long she had to wait for a helping hand, but it must be long enough to damage the nerves and cause incontinence. She was leaking faeces yet needed help to urinate. But that didn’t keep her from being left to shelter! We found her covered in her faeces and urine and extremely distressed. We immediately took her to a private clinic and after 2 rounds of stem cell therapy, she’s getting better each passing day!",
      tr: "Angel'ın kuyruğu kapıya sıkışmış ve kimse fark etmemiş. Sonunda birisi fark ettiğinde kuyruğu zaten ciddi şekilde hasar görmüştü, bu yüzden kesilmesi gerekiyordu. Bir yardım eli için ne kadar bekledi bilmiyoruz ama sinirlere zarar verecek ve idrar ve dışkı kaçırmaya neden olacak kadar uzun olmalı. Dışkı ve idrar sızdırıyordu ve tuvalet ihtiyaçlarını gidermek  için yardıma ihtiyacı vardı. Ama bu onu barınağa terk edilmekten alıkoymadı! Onu dışkısı ve idrarı ile kaplı ve son derece kötü bir durumda bulduk. Hemen özel bir kliniğe götürdük ve 2 kür kök hücre tedavisinin ardından her geçen gün daha iyiye gidiyor!",
    },
    description2: {
      eng: "She still leaks sometimes but is expected to completely recover in time. All she needs is time, patience, and a loving family. She stays in a cat hotel now and is looking for a sponsor for her.",
      tr: "Hala bazen sızdırıyor ama zamanla tamamen iyileşmesi bekleniyor. Tek ihtiyacı olan zaman, sabır ve sevgi dolu bir aile. Su an bir pansiyonda kalıyor ve koruyucu aile arıyoruz.",
    },
    sponsorStatus: false,
  };

  const contentSansa = {
    name: "Sansa",
    description1: {
      eng: "Sansal’s tail got caught in a coffee shop’s door in the neighbourhood that she lived in. The coffee shop tried to treat her tail on their own but her tail became worse. Due to the necrosis on her tail, the only option to save her was to amputate her tail. Once we took her to the vet, we learned that she was pregnant and the birth was very close. After a couple of days, we had 5 kittens. Her tail was amputated and she is in a pet hotel now.",
      tr: "Sansa'nın kuyruğu oturduğu mahallede bir kahvecinin kapısına sıkışmış. Kahve dükkanı kendi imkanlarıyla kuyruğunu tedavi etmeye çalışmış ama kuyruğu daha da kötüleşmiş. Kuyruğundaki nekroz nedeniyle onu kurtarmanın tek yolu kuyruğunu ampüte etmekti. Veterinere götürdüğümüzde hamile olduğunu ve doğumun çok yakın olduğunu öğrendik. Birkaç gün sonra 5 yavru kedimiz oldu. Kuyruğu kesildi ve şu anda bir evcil hayvan otelinde.",
    },
    description2: {
      eng: "We are looking for firstly a forever home for her and then sponsor for her veterinary and pet hotel expenses.",
      tr: "Önce ona kalıcı bir yuva, ardından veteriner ve evcil hayvan otel masrafları için sponsor arıyoruz.",
    },
    sponsorStatus: false,
  };

  const imagesAngel = [
    { url: require("../../../assets/cats/cat-cases/angel/angel-1.png") },
    { url: require("../../../assets/cats/cat-cases/angel/angel-2.png") },
    { url: require("../../../assets/cats/cat-cases/angel/angel-3.jpeg") },
  ];

  const imagesSansa = [
    { url: require("../../../assets/cats/cat-cases/sansa/sansa-1.jpeg") },
    { url: require("../../../assets/cats/cat-cases/sansa/sansa-2.png") },
    { url: require("../../../assets/cats/cat-cases/sansa/sansa-3.png") },
  ];

  return (
    <main>
      <Header />
      <div className="container half-width-container">
        <div className="justify">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <a href="https://form.jotform.com/221033421746951" target="_blank" rel="noreferrer">
            {language.lang === "eng" ? content.sponsorLinkText.eng : content.sponsorLinkText.tr}
          </a>
        </div>
        <AnimalBox images={imagesAngel} content={contentAngel} />
        <AnimalBox images={imagesSansa} content={contentSansa} />
      </div>
      <Footer />
    </main>
  );
};

export default CatsCases;
