import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Context } from "../../App";

import streetDog from "../../assets/homepage/street-dog.jpg";
import shelter from "../../assets/homepage/barinak.jpg";
import help from "../../assets/homepage/help.jpg";
import Header from "../Header/Header";
import Footer from "../Footer";

const HomePage = () => {
  const language = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentENG = {
    columnOne: {
      title: "The challenge for stray animals in Turkey",
      description:
        "Turkey is densely populated with stray animals. Everyday thousands of puppies and kittens are sold in the pet-shops and most of them found themselves abandoned on the streets within a couple of months in miserable conditions, all breeds and age, these pets are starved to death, got run over by a car and beaten or poisoned by people. In fact, it is not limited to puppies and kittens but also it is not uncommon for non-stray adult dogs and cats finding them on the street.  Unlike many developed countries, Turkey does not have a centralised system of animal control nor a law on animal rights imposing penal sanctions. That’s why the number of strays in Turkey is increasing drastically due to lack of castration. In addition, perpetrators of physical and sexual violence against animals usually get away with it without being subject to persecution. Last but not least, finding food and shelter remains a great challenge for stray animals whether in big cities or in small towns.",
    },
    columnTwo: {
      title: "Shelters in Turkey",
      description:
        "Local governments are responsible for the stray cats and dogs living in their administrative division: they have to spay and neuter them, vaccinate them against rabies, and tag them. However, the majority fails to perform such duties due to the lack of necessary medical equipment and insufficient human resource. In addition, not all municipalities have facilities tailored and designed for stray animals. Most of them have shelters but they are nothing short of death camps. To control the stray animal population, municipalities use cruel and inhuman ways, euthanizing, poisoning healthy animals or abandoning them in the middle of nowhere where they cannot find any food, instead of spaying. All in all, shelters in Turkey are not  5 star hotels for them. On the contrary, nothing more than dead camps",
    },
    columnThree: {
      title: "How you can help?",
      description:
        "‘Bir pati de sen tut’ is a legal registered animal rescue non-profit charity. That’s why we need your valuable help so we can rescue more animals and impact their lives. All of the pets rescued are seen by a veterinarian to have all their vetting needs taken care of and are then placed in a pet hotel or a foster home if we are lucky and find one. We need mostly monetary donations in any amount and of course cat and dog foods are the second most important need for us. Apart from this, we are looking for foster homes domestically in Turkey and in the countries where our dogs and cats are adopted. We have a long wish list on our 'Help Us' page if you want to take a look at it. ",
    },
  };

  const contentTR = {
    columnOne: {
      title: "Türkiye'deki sokak hayvanlarının yaşadıkları zorluklar",
      description:
        "Türkiye, sokak hayvanlarının yoğun olarak yaşadığı bir yerdir. Her gün binlerce köpek yavrusu ve kedi yavrusu evcil hayvan dükkanlarında satılıyor ve çoğu birkaç ay içinde kendilerini sokaklarda sefil koşullarda buluyor. Bu hayvanlar ya açlıktan ölüyor, ya bir araba tarafından eziliyorlar ya da insanlar tarafından dövülüyor veya zehirleniyorlar. Aslında, bu durum sadece yavru kedi ve köpeklerin başına gelmiyor. Sahipli yetişkin hayvanların da sokaklara terk edilmesi sıklıkla karşılaşılan bir durumdur. Birçok gelişmiş ülkenin aksine, Türkiye'de merkezi bir hayvan kontrol sistemi veya cezai yaptırımlar getiren hayvan hakları kanunu bulunmamaktadır. Kısırlaştırma yapılmamasından ötürü sokaklarda yaşayan sahipsiz hayvan sayısı çok keskin bir şekilde her geçen gün artmaktadır. Ayrıca hayvanlara karşı şiddet ve cinsel saldırı suçlarında bulunan kişiler herhangi bir cezai yaptırıma tabi tutulmamaktadır. Son olarak, yiyecek ve barınak bulmak, ister büyük şehirlerde ister küçük kasabalarda olsun, sokak hayvanları için büyük bir zorluk olmaya devam ediyor.",
    },
    columnTwo: {
      title: "Türkiye'deki barınaklar ",
      description:
        "Yerel yönetimler, kendi idari birimlerinde yaşayan kedi ve köpeklerin kısırlaştırılmasından, kuduza karşı aşılanmasından ve çiplenmesinden sorumludurlar. Ancak çoğunluğu gerekli tıbbi ekipman ve insan kaynağının yetersizliği nedeniyle bu görevleri yerine getirememektedir. Ayrıca, tüm belediyelerin sokak hayvanları için özel olarak tasarlanmış tesisleri yoktur. Çoğunun barınağı olsa da ölüm kamplarından başka bir şey değiller. Belediyeler sokak hayvanlarını kontrol altına almak için kısırlaştırmak yerine sağlıklı hayvanları zehirleyerek, ötenazi uygulayarak ya da yiyecek bulamayacakları yerlere terk ederek acımasız ve insanlık dışı yollara başvurmaktadır. Sonuç olarak Türkiye'deki barınaklar hayvanlar için 5 yıldızlı otel olmayıp aksine, ölüm kamplarından başka birşey değildir.",
    },
    columnThree: {
      title: "Nasıl yardım edebilirsiniz?",
      description:
        "'Bir pati de sen tut', yasal olarak kayıtlı ve kar amacı gütmeyen bir hayvan kurtarma yardım kuruluşudur. Bu nedenle, daha fazla hayvanı kurtarabilmek ve hayatlarını etkileyebilmek için değerli yardımlarınıza ihtiyacımız var. Kurtarılan tüm evcil hayvanlar tüm veterinerlik ihtiyaçlarının karşılanması için veteriner kontrolünden geçiyor ve daha sonra bir evcil hayvan oteline veya şanslıysak ve bulabilirsek koruyucu bir eve yerleştiriliyor. En çok ihtiyaç duyduğumuz yardım veteriner ve pet otel masraflarını karşılayabilmemiz için parasal bağış.  Ve tabii ki kedi ve köpek mamaları bizim için en önemli ikinci ihtiyaç. Bunun dışında yurt içinde Türkiye'de ve kedi ve köpeklerimizin sahiplenildiği ülkelerde koruyucu yuvalar arıyoruz. Bir göz atmak isterseniz, 'Yardım' sayfamızda uzun bir dilek listemiz var.",
    },
  };
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {language.lang === "eng" ? (document.title = "HomePage") : (document.title = "Ana Sayfa")}
        </title>
        <meta
          name="description"
          content="The aims of the association are to contribute to the control of the stray animal population in Turkey with castration activities, to protect the prohibited breeds, and to find a home for the animals in the streets who are neglected, abused or cannot survive."
        ></meta>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Header />
      <div className="container">
        <div className="homepage-column">
          <img src={streetDog} alt="Street dog"></img>
          <h1>
            {language.lang === "eng" ? contentENG.columnOne.title : contentTR.columnOne.title}
          </h1>
          <p>
            {language.lang === "eng"
              ? contentENG.columnOne.description
              : contentTR.columnOne.description}
          </p>
        </div>
        <div className="homepage-column">
          <img src={shelter} alt="Shelters in Turkey"></img>
          <h1>
            {language.lang === "eng" ? contentENG.columnTwo.title : contentTR.columnTwo.title}
          </h1>
          <p>
            {language.lang === "eng"
              ? contentENG.columnTwo.description
              : contentTR.columnTwo.description}
          </p>
        </div>
        <div className="homepage-column">
          <img src={help} alt="How you can help?"></img>
          <h1>
            {language.lang === "eng" ? contentENG.columnThree.title : contentTR.columnThree.title}
          </h1>
          <p>
            {language.lang === "eng"
              ? contentENG.columnThree.description
              : contentTR.columnThree.description}
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default HomePage;
