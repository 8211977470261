import Header from "../../Header/Header";
import Footer from "../../Footer";
import { Context } from "../../../App";
import { useContext, useEffect } from "react";
import img1 from "../../../assets/holdmypaw/dogsInNeed/dost1.jpeg";
import img2 from "../../../assets/holdmypaw/dogsInNeed/dost2.jpeg";
import img3 from "../../../assets/holdmypaw/dogsInNeed/silva1.jpeg";
import img4 from "../../../assets/holdmypaw/dogsInNeed/silva2.jpeg";
import img5 from "../../../assets/holdmypaw/dogsInNeed/findik1.jpeg";
import img6 from "../../../assets/holdmypaw/dogsInNeed/findik2.jpeg";
import img7 from "../../../assets/holdmypaw/dogsInNeed/pack1.jpeg";
import img8 from "../../../assets/holdmypaw/dogsInNeed/pack2.jpeg";
const DogsInNeed = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Paris") : (document.title = "Paris");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Dost-Findik-Silva",
      tr: "Dost-Findik-Silva",
    },
    intro1: {
      eng: "Hi my name is Dost. I don’t remember my past exactly but what I remembered is that somebody hit my chin into. I was a puppy and I just wanted to enjoy with people. I still don’t know the reason but my rescuer told me that not every people are the same as this person who hit me. So I still trust people. I got operated for my chin but everything is just fine. I can eat and love eating. Send me some yummy food, please.",
      tr: "Merhaba benim adım Dost. Geçmişimi tam olarak hatırlamıyorum ama hatırladığım şey, birinin çeneme vurduğu. Ben bir köpek yavrusuydum ve sadece insanlarla eğlenmek istiyordum. Nedenini hala bilmiyorum ama kurtarıcım bana her insanın bana vuran bu kişiyle aynı olmadığını söyledi. Bu yüzden hala insanlara güveniyorum. Çenemden ameliyat oldum ama her şey yolunda. Yemek yiyebiliyorum ve yemek yemeyi çok seviyorum. Bana lezzetli yiyecekler gönderebilirsiniz."
    },
    intro2: {
      eng: "Hi my name is Silva. I was born into a crowded place where there has always been traffic. When I was 2 months old when I started to enjoy playing with my siblings a car hit me. But I told you, it was a dangerous place. My hip was broken and I suffered 1 week with that broken hip. When my rescuer found me, I was about to die. My rescuer took me to a vet and I got an operation. Lucily, I recovered super fast. ",
      tr: "Merhaba benim adım Silva. Her zaman trafiğin olduğu kalabalık bir yerde doğdum. 2 aylıkken kardeşlerimle oynamaktan keyif almaya başladığımda bana bir araba çarptı. Burası tehlikeli bir yerdi. Kalçam kırılmıştı ve o kırık kalçayla 1 hafta acı çektim. Kurtarıcım beni bulduğunda ölmek üzereydim. Kurtarıcım beni veterinere götürdü ve ameliyat oldum. Şans eseri, süper hızlı iyileştim."
    },
    intro3: {
      eng: "Hi my name is Findik. I’m a shy lady and I have a cause for it. While I was just a puppy, I was kept closed up in one room with no food or walks. I don’t remember how I ended up in that home but   they left me near to a garbage when I was about to die. That garbage was our first meeting point with my rescuer. I really appreciate for what she did for me but I still don’t trust people and need time for it.",
      tr: "Merhaba benim adım Fındık. Ben utangaç bir köpeğim ve bunun için bir sebebim var. Ben sadece bir köpek yavrusuyken, yemek verilmeden veya yürüyüşe cikarilmadan bir odaya kapatıldım. O eve nasıl geldiğimi hatırlamıyorum ama ölmek üzereyken beni bir çöplüğün yanına bıraktılar. O çöp, kurtarıcımla ilk buluşma noktamızdı. Benim için yaptıkları için gerçekten minnettarım ama yine de insanlara güvenmiyorum ve bunun için zamana ihtiyacım var. "
    },
    intro4: {
      eng: "All of us need to go to the vet for a further check-up and to be castrated. Our rescuer has many dogs and cats that needs to take care. Can you help our rescuer for our expenses? ",
      tr: "Hepimizin kontrol için veterinere gitmesi ve kisirlasmasi gerekiyor. Kurtarıcımızın ilgilenmesi gereken birçok köpek ve kedisi var. Kurtarıcımıza masraflarımız için yardım eder misiniz?"
    },
    intro5: {
      eng: "Thank you so much for listening our stories. If you share this post on your accounts, maybe it helps us to collect the amount earlier than we though. Why not?",
      tr: "Hikayelerimizi dinlediğiniz için çok teşekkür ederiz. Bu gönderiyi hesaplarınızda paylaşırsanız  belki de düşündüğümüzden daha önce tutarı toplamamamiza yardımcı olur. Neden olmasın?"
    },
    intro6: {
      eng: "You can donate from the following fundraising link:",
      tr: "Aşağıdaki bağış toplama linkinden bağışta bulunabilirsiniz:"
    },
    buttonText1: {
      eng: "Online Donation",
      tr: "Online Bagis"
    },
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container background-image">
        <div className="intro holdmypaw">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro1.eng : content.intro1.tr}</p>
          <div className="imageContainer">
            <img src={img1} alt="asd"></img>
            <img src={img2} alt="asd"></img>
          </div>
          <p>{language.lang === "eng" ? content.intro2.eng : content.intro2.tr}</p>
          <div className="imageContainer">
            <img src={img3} alt="asd"></img>
            <img src={img4} alt="asd"></img>
          </div>
          <p>{language.lang === "eng" ? content.intro3.eng : content.intro3.tr}</p>
          <div className="imageContainer">
            <img src={img5} alt="asd"></img>
            <img src={img6} alt="asd"></img>
          </div>

          <p>{language.lang === "eng" ? content.intro4.eng : content.intro4.tr}</p>
          <div className="imageContainer">
            <img src={img7} alt="asd"></img>
            <img src={img8} alt="asd"></img>
          </div>
          <p>{language.lang === "eng" ? content.intro5.eng : content.intro5.tr}</p>
          <p>{language.lang === "eng" ? content.intro6.eng : content.intro6.tr}</p>
          <div className="imageContainer">
            <button className="donation-button">
              <a href="https://fonzip.com/birpatidesentut/bir-pati-de-sen-tut" target="_blank" rel="noopener noreferrer">
                {language.lang === "eng" ? content.buttonText1.eng : content.buttonText1.tr}
              </a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default DogsInNeed;
