import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import HomePage from "./Components/Pages/HomePage";
import AboutPage from "./Components/Pages/AboutPage";
import HelpUsPage from "./Components/Pages/HelpUsPage";
import ContactPage from "./Components/Pages/ContactPage";
import CatsPage from "./Components/Pages/CatsPage";
import DogsPage from "./Components/Pages/DogsPage";
import HoldMyPaw from "./Components/Pages/HoldMyPaw";
import NotFound from "./Components/Pages/NotFound";
import DogoPitbull from "./Components/Pages/Dogs/DogoPitbull";
import DogsCases from "./Components/Pages/Dogs/DogsCases";
import DogsAdoption from "./Components/Pages/Dogs/DogsAdoption";
import CatsCases from "./Components/Pages/Cats/CatsCases";
import CatsAdoption from "./Components/Pages/Cats/CatsAdoption";
import HappyTails from "./Components/Pages/HappyTails";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import TermsOfUse from "./Components/Pages/TermsOfUse";
import Paris from "./Components/Pages/HoldMyPaw/paris";
import DogsInNeed from "./Components/Pages/HoldMyPaw/dogsInNeed";

const Context = React.createContext();

const App = () => {
  const TRACKING_ID = "G-B0BCR71GN5";
  ReactGA.initialize(TRACKING_ID);

  const [lang, setLang] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("lang");
    const initialValue = JSON.parse(saved);
    return initialValue || "eng";
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem("lang", JSON.stringify(lang));
  }, [lang]);

  return (
    <Context.Provider value={{ lang, setLang }}>
      <div className="App" id="app-id">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/helpus" element={<HelpUsPage />} />
          <Route path="/cats" element={<CatsPage />} />
          <Route path="/dogs" element={<DogsPage />} />
          <Route path="/holdmypaw" element={<HoldMyPaw />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/happytails" element={<HappyTails />} />
          <Route path="/dogs/dogo-pitbull" element={<DogoPitbull />} />
          <Route path="/dogs/cases" element={<DogsCases />} />
          <Route path="/dogs/adoption" element={<DogsAdoption />} />
          <Route path="/cats/cases" element={<CatsCases />} />
          <Route path="/holdmypaw/paris" element={<Paris />} />
          <Route path="/holdmypaw/dogsInNeed" element={<DogsInNeed />} />
          <Route path="/cats/adoption" element={<CatsAdoption />} />
          <Route path="/termsOfUse" element={<TermsOfUse />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Context.Provider>
  );
};
export { Context };
export default App;
