import { Context } from "../App";
import { useContext } from "react";
import SimpleImageSlider from "react-simple-image-slider";

const AnimalBox = ({ images, content }) => {
  const language = useContext(Context);

  const selfContent = {
    lookingForSponsorText: {
      eng: "Looking for sponsor :(",
      tr: "Sponsor arıyor :(",
    },
    sponseredText: {
      eng: "Sponsored :)",
      tr: "Sponsor bulundu :)",
    },
  };

  return (
    <div className="animal-box-container">
      <div className="animal-box">
        <div className="content">
          <h2>{content.name}</h2>
          <p>{language.lang === "eng" ? content.description1.eng : content.description1.tr}</p>
          <p>{language.lang === "eng" ? content.description2.eng : content.description2.tr}</p>
          {content.sponsorStatus ? (
            <div className="sponsor-status sponsored">
              {language.lang === "eng"
                ? selfContent.sponseredText.eng
                : selfContent.sponseredText.tr}
            </div>
          ) : (
            <div className="sponsor-status looking-for-sponsor">
              {language.lang === "eng"
                ? selfContent.lookingForSponsorText.eng
                : selfContent.lookingForSponsorText.tr}
            </div>
          )}
        </div>
        <div className="images">
          <SimpleImageSlider
            width={400}
            height={400}
            images={images}
            showBullets={true}
            showNavs={true}
            /* navMargin={50} */
          />
        </div>
      </div>
    </div>
  );
};

export default AnimalBox;
