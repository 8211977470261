import Header from "../Header/Header";
import Footer from "../Footer";

import { Context } from "../../App";
import { useContext, useEffect } from "react";

import contactImage from "../../assets/contactUs/contactUs.jpeg";

const ContactPage = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Contact") : (document.title = "İletişim");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Contact Us",
      tr: "Bize Ulaşın",
    },
    description1: {
      eng: "In Turkey the number of abandoned and stray dogs and cats is very large. We truly feel heartbroken knowing that there are so many animals suffering from different sorts of abuse and neglect. Therefore, our aim is to contribute to the wellbeing of rescue dogs and cats.",
      tr: "Türkiye'de terkedilmiş ve başıboş köpek ve kedilerin sayısı çok fazla. Farklı türde istismar ve ihmale maruz kalan çok sayıda hayvan olduğunu bilmek bizi gerçekten üzüyor. Bu nedenle amacımız, kurtardığımız köpeklerin ve kedilerin daha iyi şartlarda yaşamalarına katkıda bulunmaktır.",
    },
    description2: {
      eng: "The goal of this website is to tell you more about dogs and cats in need and to facilitate their adoption and rehoming. If you are looking to adopt, you need to fill out the adoption application forms and if you want us to rescue more animals you can sponsor for our rescued animals. Don't forget saving one animal won't change the world, but it will change the world forever for that animal.",
      tr: "Bu web sitesinin amacı, size yardıma ihtiyacı olan köpekler ve kediler hakkında daha fazla bilgi vermek ve onların sahiplenilmelerini kolaylaştırmaktır. Sahiplenmek istiyorsanız sahiplendirme başvuru formlarını doldurmanız gerekli ve daha fazla hayvan kurtarmamızı istiyorsanız kurtarılan hayvanlarımıza sponsor olarak bize destek olabilirsiniz. Unutmayın ki, bir hayvanı kurtarmak dünyayı değiştirmez ama o hayvan için dünyayı sonsuza dek değiştirir.",
    },
    dogAdoptionLinkText: {
      eng: "Dog Adoption Form",
      tr: "Köpek Sahiplenme Formu",
    },
    catAdoptionLinkText: {
      eng: "Cat Adoption Form",
      tr: "Kedi Sahiplenme Formu",
    },
    sponsorLinkText: {
      eng: "Sponsorship Form",
      tr: "Sponsorluk Formu",
    },
    contactLinkText: {
      eng: "Contact Us Form",
      tr: "Bize Ulaşın Formu",
    },
    email: {
      eng: "E-mail",
      tr: "E-Posta",
    },
    phone: {
      eng: "Phone",
      tr: "Telefon",
    },
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container background-image">
        <div className="contact-container">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <div className="contact-image">
            <img src={contactImage} alt="a dog and a cat typing in front of a laptop"></img>
          </div>
          <p>{language.lang === "eng" ? content.description1.eng : content.description1.tr}</p>
          <p>{language.lang === "eng" ? content.description2.eng : content.description2.tr}</p>

          <div className="button-container">
            <button className="donation-button">
              <a href="https://form.jotform.com/220504169691052" target="_blank" rel="noreferrer">
                {language.lang === "eng"
                  ? content.dogAdoptionLinkText.eng
                  : content.dogAdoptionLinkText.tr}
              </a>
            </button>
            <button className="donation-button">
              <a href="https://form.jotform.com/221033846148958" target="_blank" rel="noreferrer">
                {language.lang === "eng"
                  ? content.catAdoptionLinkText.eng
                  : content.catAdoptionLinkText.tr}
              </a>
            </button>
            <button className="donation-button">
              <a href="https://form.jotform.com/221033421746951" target="_blank" rel="noreferrer">
                {language.lang === "eng" ? content.sponsorLinkText.eng : content.sponsorLinkText.tr}
              </a>
            </button>
            <button className="donation-button">
              <a href="https://form.jotform.com/220856018629056" target="_blank" rel="noreferrer">
                {language.lang === "eng" ? content.contactLinkText.eng : content.contactLinkText.tr}
              </a>
            </button>
          </div>
          <div className="info">
            <p>
              {language.lang === "eng" ? content.email.eng : content.email.tr}:
              arikdoganrabia@gmail.com
            </p>
            <p>
              {language.lang === "eng" ? content.phone.eng : content.phone.tr}: +90 506 572 60 82
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default ContactPage;
