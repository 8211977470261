import Header from "../../Header/Header";
import Footer from "../../Footer";
import AnimalBoxAdoption from "../../AnimalBoxAdoption";
import { Context } from "../../../App";
import { useContext, useEffect } from "react";

const CatsAdoption = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng"
      ? (document.title = "Cats Adoption")
      : (document.title = "Kedi Sahiplendirme");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    title: {
      eng: "Our fury friends looking for homes.",
      tr: "Sahiplerini bekleyen tüylü dostlarımız.",
    },
    intro: {
      eng: "We place our rescued animals in pet hotels or in foster homes. The following cats were rescued from bad conditions and are looking for their forever homes.",
      tr: "Kurtarılan hayvanlarımızı evcil hayvan otellerine veya koruyucu evlere yerleştiriyoruz. Aşağıdaki kediler kötü koşullardan kurtarıldı ve ömürlük yuvalarını arıyorlar.",
    },
    sponsorLinkText: {
      eng: "Click for adoption",
      tr: "Sahiplenmek icin tıklayın",
    },
  };

  const contentNarin = {
    name: "Narin",
    description1: {
      eng: "She was rescued from the street. We thought we found her a forever home when she was 2 months old. But the family neglected her, she got pregnant and fell down from the unsecured window. Luckily she had no injuries but lost her babies. She'll arrive with the new family spayed, vaccinated, microchipped and with her passport.",
      tr: "Sokaktan kurtarıldı. 2 aylıkken ona ömürlük bir yuva bulduğumuzu düşündük. Ama ailesi onu ihmal etti, hamile kaldı ve emniyetsiz pencereden düştü. Şans eseri herhangi bir yaralanma olmadı ama bebeklerini kaybetti. Yeni ailesine kısırlaştırılmış, aşılanmış, mikroçipli ve pasaportuyla gelecek.",
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "She was rescued from the street. We thought we found her a forever home when she was 2 months old. But the family neglected her, she got pregnant and fell down from the unsecured window. Luckily she had no injuries but lost her babies. She'll arrive with the new family spayed, vaccinated, microchipped and with her passport.",
      tr: "Sokaktan kurtarıldı. 2 aylıkken ona ömürlük bir yuva bulduğumuzu düşündük. Ama ailesi onu ihmal etti, hamile kaldı ve emniyetsiz pencereden düştü. Şans eseri herhangi bir yaralanma olmadı ama bebeklerini kaybetti. Yeni ailesine kısırlaştırılmış, aşılanmış, mikroçipli ve pasaportuyla gelecek.",
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Female",
      tr: "Cinsiyet: Dişi",
    },
    birthday: {
      eng: "Birthday: 01/12/2020",
      tr: "Doğum günü: 01/12/2020",
    },
    breed: {
      eng: "Breed: Tabby cat",
      tr: "Irk: Tekir",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Yes",
      tr: "Çocuklarla arası iyi: Evet",
    },
    goodWithDogs: {
      eng: "Good with dogs: Unknown",
      tr: "Köpeklerle arası iyi: Bilinmiyor",
    },
    goodWithCats: {
      eng: "Good with cats: Yes",
      tr: "Kedilerle arası iyi: Evet",
    },
  };

  const imagesNarin = [
    { url: require("../../../assets/cats/cat-cases/narin/narin-1.jpeg") },
    { url: require("../../../assets/cats/cat-cases/narin/narin-2.jpeg") },
    { url: require("../../../assets/cats/cat-cases/narin/narin-3.jpeg") },
  ];

  const contentAyse = {
    name: "Ayse",
    description1: {
      eng: "Ayşe was neutered at a shelter. Unfortunately, due to poor sanitary conditions, her wound got infected and she developed non-effusive (dry) FIP. She suffered symptoms for one and a half months! For all that time she had to endure all the pain untreated in a shelter cage. When we heard about her and that they decided to euthanize her, we immediately moved her to a private clinic and started treatment. She has received GS44 medication for 3 months and and responded so well. After the treatment, we had to wait for 3 months for an observation period and luckily she did not show any more symptoms and all the tests we did to her on the private clinic shows that she is not different than a healthy cat right now.She is a silent cat but love to play and be petted. In the first introduction she would be shy but it does not mean that she is in distance with people. She just wait the first interaction from a person. She loves sitting on your laps :) She can stay a home alone for long hours.",
      tr: "Ayşe barınakta kısırlaştırıldı. Ne yazık ki, kötü hijyen koşulları nedeniyle yarası enfeksiyon kaptı ve efüzyonsuz (kuru) FIP geliştirdi. Bir buçuk ay boyunca semptomlar yaşadı! Tüm bu süre boyunca, bir barınak kafesinde tedavi edilmeyen tüm acılara katlanmak zorunda kaldı. Kendisini ötenazi kararı aldıklarını duyunca hemen özel bir kliniğe taşıyıp tedaviye başladık. 3 ay boyunca GS44 ilacı aldı ve çok iyi yanıt verdi. Tedavi sonrası 3 ay kadar gözlem süresi beklemek zorunda kaldık ve çok şükür herhangi bir belirti göstermedi ve kendisine özel klinikte yaptığımız tüm testler şu an sağlıklı bir kediden farkı olmadığını gösteriyor.O sessiz bir kedi ama oynamayı ve sevilmeyi seviyor. İlk tanışmada çekingen olur ama bu insanlarla mesafeli olduğu anlamına gelmez. Sadece bir kişiden ilk etkileşimi bekler. Kucağınıza oturmayı çok seviyor :) Evde uzun saatler tek başına kalabilir."
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "Ayşe was neutered at a shelter. Unfortunately, due to poor sanitary conditions, her wound got infected and she developed non-effusive (dry) FIP. She suffered symptoms for one and a half months! For all that time she had to endure all the pain untreated in a shelter cage. When we heard about her and that they decided to euthanize her, we immediately moved her to a private clinic and started treatment. She has received GS44 medication for 3 months and and responded so well. After the treatment, we had to wait for 3 months for an observation period and luckily she did not show any more symptoms and all the tests we did to her on the private clinic shows that she is not different than a healthy cat right now.She is a silent cat but love to play and be petted. In the first introduction she would be shy but it does not mean that she is in distance with people. She just wait the first interaction from a person. She loves sitting on your laps :) She can stay a home alone for long hours.",
      tr: "Ayşe barınakta kısırlaştırıldı. Ne yazık ki, kötü hijyen koşulları nedeniyle yarası enfeksiyon kaptı ve efüzyonsuz (kuru) FIP geliştirdi. Bir buçuk ay boyunca semptomlar yaşadı! Tüm bu süre boyunca, bir barınak kafesinde tedavi edilmeyen tüm acılara katlanmak zorunda kaldı. Kendisini ötenazi kararı aldıklarını duyunca hemen özel bir kliniğe taşıyıp tedaviye başladık. 3 ay boyunca GS44 ilacı aldı ve çok iyi yanıt verdi. Tedavi sonrası 3 ay kadar gözlem süresi beklemek zorunda kaldık ve çok şükür herhangi bir belirti göstermedi ve kendisine özel klinikte yaptığımız tüm testler şu an sağlıklı bir kediden farkı olmadığını gösteriyor.O sessiz bir kedi ama oynamayı ve sevilmeyi seviyor. İlk tanışmada çekingen olur ama bu insanlarla mesafeli olduğu anlamına gelmez. Sadece bir kişiden ilk etkileşimi bekler. Kucağınıza oturmayı çok seviyor :) Evde uzun saatler tek başına kalabilir."
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Female",
      tr: "Cinsiyet: Dişi",
    },
    birthday: {
      eng: "Birthday: 01/12/2020",
      tr: "Doğum günü: 01/12/2020",
    },
    breed: {
      eng: "Breed: Tortishell",
      tr: "Irk: Tortishell",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Yes",
      tr: "Çocuklarla arası iyi: Evet",
    },
    goodWithDogs: {
      eng: "Good with dogs: Unknown",
      tr: "Köpeklerle arası iyi: Bilinmiyor",
    },
    goodWithCats: {
      eng: "Good with cats: Yes",
      tr: "Kedilerle arası iyi: Evet",
    },
  };

  const imagesAyse = [
    { url: require("../../../assets/cats/ayse/ayse-1.jpeg") },
    { url: require("../../../assets/cats/ayse/ayse-2.jpeg") },
    { url: require("../../../assets/cats/ayse/ayse-3.jpeg") },
    { url: require("../../../assets/cats/ayse/ayse-4.jpeg") },
    { url: require("../../../assets/cats/ayse/ayse-5.jpeg") },
    { url: require("../../../assets/cats/ayse/ayse-6.jpeg") },

  ];

  const contentAsure = {
    name: "Asure",
    description1: {
      eng: "Asure was rescued from a municipality shelter in turkey subject to bad news on the social media. We don’t know how such beauty ended up in the shelter but we couldn’t close our eyes to leave her in that mess. It was literally a mess. Everywhere was full of with dirt and cat poop. She was fully vet checkup, spayed, up-to-date core vaccinated, has microchip and passport. Asure has a wonderful character ‒ she is a calm and sweet girl loving daytime napping in beds. She shares a place with other cats and get along with them super well. And the best part – she simply loooves being with people! Her go-to way of showing affection is rubbing her people. She can stay alone at home for long hours. She just watches outside from the window, one of her favorites.",
      tr: "Asure, sosyal medyada kötü haberlere konu olan Türkiye'de belediye barınağından kurtarıldı. Böyle bir güzellik nasıl oldu da barınağa düştü bilmiyoruz ama onu o karmaşanın içinde bırakmak istemedik. Kelimenin tam anlamıyla bir karmaşaydı. Her yer kir ve kedi kakasıyla doluydu. Veteriner kontrolü yapıldı, kısırlaştırıldı, temel aşıları yapıldı, mikroçipi ve pasaportu var. Asurenin harika bir karakteri var - gündüzleri yataklarında kestirmeyi seven sakin ve tatlı bir kız. Başka kedilerle aynı ortamda kalıyor ve onlarla çok iyi anlaşıyor. Ve en iyi yanı - insanlarla birlikte olmayı çok seviyor! Evde uzun saatler yalnız kalabilir. En sevdiği şeylerden biri pencereden dışarıyı izlemek."
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "Asure was rescued from a municipality shelter in turkey subject to bad news on the social media. We don’t know how such beauty ended up in the shelter but we couldn’t close our eyes to leave her in that mess. It was literally a mess. Everywhere was full of with dirt and cat poop. She was fully vet checkup, spayed, up-to-date core vaccinated, has microchip and passport. Asure has a wonderful character ‒ she is a calm and sweet girl loving daytime napping in beds. She shares a place with other cats and get along with them super well. And the best part – she simply loooves being with people! Her go-to way of showing affection is rubbing her people. She can stay alone at home for long hours. She just watches outside from the window, one of her favorites.",
      tr: "Asure, sosyal medyada kötü haberlere konu olan Türkiye'de belediye barınağından kurtarıldı. Böyle bir güzellik nasıl oldu da barınağa düştü bilmiyoruz ama onu o karmaşanın içinde bırakmak istemedik. Kelimenin tam anlamıyla bir karmaşaydı. Her yer kir ve kedi kakasıyla doluydu. Veteriner kontrolü yapıldı, kısırlaştırıldı, temel aşıları yapıldı, mikroçipi ve pasaportu var. Asurenin harika bir karakteri var - gündüzleri yataklarında kestirmeyi seven sakin ve tatlı bir kız. Başka kedilerle aynı ortamda kalıyor ve onlarla çok iyi anlaşıyor. Ve en iyi yanı - insanlarla birlikte olmayı çok seviyor! Evde uzun saatler yalnız kalabilir. En sevdiği şeylerden biri pencereden dışarıyı izlemek."
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Female",
      tr: "Cinsiyet: Dişi",
    },
    birthday: {
      eng: "Birthday: 01/04/2022",
      tr: "Doğum günü: 01/04/2022",
    },
    breed: {
      eng: "Breed: Calico",
      tr: "Irk: Kaliko",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Yes",
      tr: "Çocuklarla arası iyi: Evet",
    },
    goodWithDogs: {
      eng: "Good with dogs: Yes but with an intro",
      tr: "Köpeklerle arası iyi: Evet ancak aşamalı bir şekilde tanıştırılması gerekli",
    },
    goodWithCats: {
      eng: "Good with cats: Yes",
      tr: "Kedilerle arası iyi: Evet",
    },
  };

  const imagesAsure = [
    { url: require("../../../assets/cats/asure/asure-1.jpeg") },
    { url: require("../../../assets/cats/asure/asure-2.jpeg") },
    { url: require("../../../assets/cats/asure/asure-3.jpeg") },
    { url: require("../../../assets/cats/asure/asure-4.jpeg") },
    { url: require("../../../assets/cats/asure/asure-5.jpeg") },
    { url: require("../../../assets/cats/asure/asure-6.jpeg") },
    { url: require("../../../assets/cats/asure/asure-7.jpeg") },
    { url: require("../../../assets/cats/asure/asure-8.jpeg") },
  ];

  const contentGumus = {
    name: "Gumus",
    description1: {
      eng: "Gumus was rescued from a municipal shelter in Turkey where it has been documented that animals are brutally mistreated. We don’t know how such a beauty ended up in the shelter, but we couldn’t walk away and leave her in that mess. The living conditions were disgraceful, full of filth and cat poop. Since being rescued, she has been fully vet checked, spayed, given up-to-date core vaccines, and has a microchip and passport. Gumus has a wonderful character ‒ she is a calm and sweet girl who loves daytime napping in beds. She shares a place with a cat and a small dog.  She gets along with both of them very well. And the best part – she simply loves being with people! She shows her gratitude and affection to her people by rubbing up against them. She is also happy to stay home alone for long hours. ",
      tr: "Gümüş, Türkiye'de  hayvanlara vahşice kötü muamele yapıldığı belgelenen b’r belediye barınağından kurtarıldı. Böyle bir güzellik nasıl oldu da barınakta kaldı bilmiyoruz ama öylece çekip gidip onu bu karmaşanın içinde bırakamazdık. Yaşam koşulları utanç vericiydi, pislik ve kedi kakasıyla doluydu. Kurtarıldığından beri tamamen veteriner kontrolünden geçirildi, kısırlaştırıldı, güncel temel aşıları yapıldı. Mikroçipi ve pasaportu var. Gümüş harika bir karaktere sahip - gündüzleri yataklarda uyumayı seven sakin ve tatlı bir kız. Bir kedi ve küçük bir köpekle aynı evi paylaşıyor. İkisiyle de çok iyi anlaşıyor. Ve en iyi yanı - insanlarla birlikte olmayı çok seviyor! Sahibine olan minnettarlığını ve sevgisini onlara sürtünerek gösteriyor. Evde uzun saatler yalnız kalmaktan da mutlu."
    },
    title1: {
      eng: "Story",
      tr: "Hikayesi",
    },
    story: {
      eng: "Gumus was rescued from a municipal shelter in Turkey where it has been documented that animals are brutally mistreated. We don’t know how such a beauty ended up in the shelter, but we couldn’t walk away and leave her in that mess. The living conditions were disgraceful, full of filth and cat poop. Since being rescued, she has been fully vet checked, spayed, given up-to-date core vaccines, and has a microchip and passport. Gumus has a wonderful character ‒ she is a calm and sweet girl who loves daytime napping in beds. She shares a place with a cat and a small dog.  She gets along with both of them very well. And the best part – she simply loves being with people! She shows her gratitude and affection to her people by rubbing up against them. She is also happy to stay home alone for long hours. ",
      tr: "Gümüş, Türkiye'de  hayvanlara vahşice kötü muamele yapıldığı belgelenen b’r belediye barınağından kurtarıldı. Böyle bir güzellik nasıl oldu da barınakta kaldı bilmiyoruz ama öylece çekip gidip onu bu karmaşanın içinde bırakamazdık. Yaşam koşulları utanç vericiydi, pislik ve kedi kakasıyla doluydu. Kurtarıldığından beri tamamen veteriner kontrolünden geçirildi, kısırlaştırıldı, güncel temel aşıları yapıldı. Mikroçipi ve pasaportu var. Gümüş harika bir karaktere sahip - gündüzleri yataklarda uyumayı seven sakin ve tatlı bir kız. Bir kedi ve küçük bir köpekle aynı evi paylaşıyor. İkisiyle de çok iyi anlaşıyor. Ve en iyi yanı - insanlarla birlikte olmayı çok seviyor! Sahibine olan minnettarlığını ve sevgisini onlara sürtünerek gösteriyor. Evde uzun saatler yalnız kalmaktan da mutlu."
    },
    title2: {
      eng: "Details",
      tr: "Detaylar",
    },
    gender: {
      eng: "Sex: Female",
      tr: "Cinsiyet: Dişi",
    },
    birthday: {
      eng: "Birthday: 01/04/2022",
      tr: "Doğum günü: 01/04/2022",
    },
    breed: {
      eng: "Breed: Grey Tabby",
      tr: "Irk: Tekir",
    },
    spay: {
      eng: "Spay/Neuter: Yes",
      tr: "Kısır: Evet",
    },
    specialNeeds: {
      eng: "Special Needs: No",
      tr: "Özel İhtiyaç: Hayır",
    },
    microchipped: {
      eng: "Microchipped: Yes",
      tr: "Mikroçipli: Evet",
    },
    vaccinated: {
      eng: "Vaccinated: Yes",
      tr: "Aşılı: Evet",
    },
    goodWithKids: {
      eng: "Good with kids: Yes",
      tr: "Çocuklarla arası iyi: Evet",
    },
    goodWithDogs: {
      eng: "Good with dogs: Yes but with an intro",
      tr: "Köpeklerle arası iyi: Evet ancak aşamalı bir şekilde tanıştırılması gerekli",
    },
    goodWithCats: {
      eng: "Good with cats: Yes",
      tr: "Kedilerle arası iyi: Evet",
    },
  };

  const imagesGumus = [
    { url: require("../../../assets/cats/gumus/gumus-1.jpeg") },
    { url: require("../../../assets/cats/gumus/gumus-2.jpeg") },
    { url: require("../../../assets/cats/gumus/gumus-3.jpeg") },
    { url: require("../../../assets/cats/gumus/gumus-4.jpeg") },
    { url: require("../../../assets/cats/gumus/gumus-5.jpeg") },
    { url: require("../../../assets/cats/gumus/gumus-6.jpeg") },
    { url: require("../../../assets/cats/gumus/gumus-7.jpeg") },
  ];

  return (
    <main>
      <Header />
      <div className="container half-width-container">
        <div className="justify">
          <h1>{language.lang === "eng" ? content.title.eng : content.title.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
          <a href="https://form.jotform.com/221033846148958" target="_blank" rel="noreferrer">
            {language.lang === "eng" ? content.sponsorLinkText.eng : content.sponsorLinkText.tr}
          </a>
        </div>
        <AnimalBoxAdoption images={imagesNarin} content={contentNarin} />
        <AnimalBoxAdoption images={imagesAyse} content={contentAyse} />
        <AnimalBoxAdoption images={imagesAsure} content={contentAsure} />
        <AnimalBoxAdoption images={imagesGumus} content={contentGumus} />
      </div>
      <Footer />
    </main>
  );
};

export default CatsAdoption;
