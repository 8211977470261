import Header from "../Header/Header";
import Footer from "../Footer";
import { Context } from "../../App";
import { useContext, useEffect } from "react";
import tales from "../../assets/happyTails/tales.jpeg";
import fiona from "../../assets/happyTails/fiona.jpeg";
import sebnem from "../../assets/happyTails/sebnem.jpeg";
import shy from "../../assets/happyTails/happyTails-1.png";
import lucy from "../../assets/happyTails/happyTails-3.jpeg";
import cekuSans from "../../assets/happyTails/happyTails-4.jpeg";
import papyon from "../../assets/happyTails/happyTails-8.png";
import surmeli from "../../assets/happyTails/happyTails-9.jpeg";
import paris from "../../assets/happyTails/happyTails-10.png";
import kartopu from "../../assets/happyTails/happyTails-11.png";
import kaju from "../../assets/happyTails/happyTails-12.jpg";
import gofrik from "../../assets/happyTails/gofrik.jpg";

const HappyTails = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Happy Tails") : (document.title = "Mutlu Patiler");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    pageTitle: {
      eng: "Happy Tails",
      tr: "Mutlu Patiler",
    },
    intro1: {
      eng: "Seeing the animals we rescued happy in their new homes is inspiring for all of us. It shows us the difference our efforts have made, and rewards us with knowing the strays we rescued now have a secure future, and are loved. ",
      tr: "Kurtardığımız hayvanları yeni yuvalarında mutlu görmek hepimize ilham ve mutluluk veriyor. Çabalarımızın yarattığı farkı bize gösteriyor ve kurtardığımız hayvanların güvenli bir geleceğe sahip olduğunu ve sevildiğini bilmekte bizlerı ayrıca mutlu ediyor.",
    },
    intro2: {
      eng: "Here are some of our rescued friends and their happy endings.",
      tr: "İşte kurtarılan hayvanlarımızdan bazıları ve mutlu sonları.",
    },
  };

  const cardContent = {
    tales: {
      title: {
        eng: "Tales",
        tr: "Tales",
      },
      description: {
        eng: "Thales once had a family. Don’t let our word choice mislead you. A real family is always there for you and never chooses to leave you behind when they move abroad, like Thales’ so-called family did. When they told us of a cat abandoned in a park, who would cry and desperately tag along behind strangers, We immediately took him to safety and had him castrated and vaccinated.After 1.5 years, he found a home in Amsterdam and having a dog friend there.",
        tr: "Thales'in bir zamanlar bir ailesi vardı. Kelime seçimimiz sizi yanıltmasın. Gerçek bir aile her zaman yanımızdadır ve Thales'in sözde ailesinin yaptığı gibi, yurt dışına taşındıklarında sizi asla geride bırakmayı seçmezler. Bir parkta terk edilmiş, ağlayan ve çaresizce yabancıların arkasına takılan bir kediden bahsettiklerinde, hemen güvenli bir yere götürdük ve kısırlaştırıp aşılattırdık. 1.5 yıl sonra Amsterdamda bir yuva bulduk ve şimdi bir köpek arkadaşı var.",
      },
    },
    fiona: {
      title: {
        eng: "Fiona",
        tr: "Fiona",
      },
      description: {
        eng: "We met Fiona in a coffee shop. She was a kitten and went through from one table to another. It was obvious that she was very hungary. We couldn't leave her behind us and took her to a vet for a check up. She was luckily very healty. She is neutered, vaccinated and microchipped. She is in Amsterdam and loves napping with her cat sister.",
        tr: "Fiona ile bir kafede tanıştık. Küçücük bir yavruydu ve bir masadan diğerine koşturuyordu. Çok aç olduğu her halinden belliydi. Onu arkamızda bırakamadık ve kontrol için veterinere götürdük. Neyse ki çok sağlıklıydı. Kısırlaştırılmış, aşıları yapılmış ve mikroçiplidir. Şimdi Amsterdamda ve kedi kardeşiyle uyumayı çok seviyor. ",
      },
    },
    sebnem: {
      title: {
        eng: "Sebnem",
        tr: "Sebnem",
      },
      description: {
        eng: "Once we found sebnem, she was crying. She must have been abandoned as her nails were clipped and very social with humans. We took her to the vet for castration but she had already spayed. As she does not know how to survive in the streets, we placed her in a pet hotel. After looking a home for her for 1 year, she found one of the best home in Amsterdam.",
        tr: "Şebnem'i bulduğumuzda ağlıyordu. Tırnakları kesilmiş ve insanlarla çok sosyal olduğu için terk edilmiş olmalıydı.  Onu kısırlaştırma için veterinere götürdük ama çoktan kısırlaştırılmıştı. Sokakta nasıl hayatta kalacağını bilmediği için onu bir evcil hayvan oteline yerleştirdik. 1 yıl yuva aradıktan sonra, en iyi yuvalardan birine sahip oldu ve şimdi Amsterdamda. ",
      },
    },
    shy: {
      title: {
        eng: "Shy",
        tr: "Shy",
      },
      description: {
        eng: "Shy was one of the dog in our feeding spot. One day, when we went the feeding spot, shy lied in a pool of blood. She most probably attacked by other dogs. We took her to the vet and got her treathed. It was not an option to leave her the same area. So we placed her in a pet hotel. After 6 months, we finally found a home in England. She is very happy and safe now. ",
        tr: "Shy, besleme noktamızdaki köpeklerden biriydi. Bir gün, besleme yerine gittiğimizde, Shy'ı kanlar içinde yatarken bulduk. Muhtemelen diğer köpekler tarafından saldırıya uğradı. Veterinere götürdük ve tedavi ettirdik. Aynı bölgeye bırakmak artık bir seçenek değildi. Bu yüzden bir evcil hayvan oteline yerleştirdik. 6 ay sonra nihayet İngiltere'de bir ev bulduk. O şimdi çok mutlu ve güvende.",
      },
    },
    lucy: {
      title: {
        eng: "Lucy",
        tr: "Lucy",
      },
      description: {
        eng: "Lucy's mother could not gave birth and she had to have caesarean operation. Lucy and her mother stayed in the vet for almost 2 months. Luckily, we found one of the best home for this litte girl. She is very happy and play games with her big sister in her forever home. She is in Germany now.",
        tr: "Lucy'nin annesi doğum yapamadı ve sezaryen ameliyatı olması gerekiyordu. Lucy ve annesi neredeyse 2 ay veterinerde kaldı. Neyse ki, bu küçük kız için en iyi yuvalardan birini bulduk. Şu an Almanya' da ve ömürlük yuvasında ablasıyla cok mutlu ve oyunlar oynuyor.",
      },
    },
    cekuSans: {
      title: {
        eng: "Ceku & Şans",
        tr: "Ceku & Şans",
      },
      description: {
        eng: "Ceku and Sans crossed paths at different times in the same home. Sans was a stray cat and she was about to die when we found, while Ceku was a kitten when we found a forever home for here. Sans waited on the pet hotel for almost 6 months and ceku was abondoned after a while. These two girls now llives in the same home and they are very happy.",
        tr: "Ceku ve Şans'ın yolları aynı evde farklı zamanlarda kesişti. Şans bir sokak kedisiydi ve onu bulduğumuzda ölmek üzereydi, Ceku ise kalıcı bir yuva bulduğumuzda bir yavru kediydi. Şans, yaklaşık 6 ay boyunca pet otelde yuvasına gitmeyi bekledi ve ceku bir süre sonra terk edildi. Bu iki kız şimdi aynı evde yaşıyor ve çok mutlular.",
      },
    },
    papyon: {
      title: {
        eng: "Papyon",
        tr: "Papyon",
      },
      description: {
        eng: "We found Papyon on a cold winter day. He was a puppy and abandoned to the street where he could not survive. He got parvovirus but he managed to survive. After 2 months in a pet hotel, we finally found the best forever home for him. He is very happy with his golden brother.",
        tr: "Papyon'u soğuk bir kış gününde bulduk. Hayatta kalamayacağı sokaklara terkedilmiş bir yavruydu bulduğumuzda. Parvovirüs kaptı ama hayatta kalmayı başardı. Bir evcil hayvan otelinde 2 ay geçirdikten sonra nihayet onun için en iyi yuvayı bulduk. Golden kardeşiyle çok mutlu.",
      },
    },
    surmeli: {
      title: {
        eng: "Sürmeli",
        tr: "Sürmeli",
      },
      description: {
        eng: "Sürmeli was taken from her mother by the children in the neighbourhood that she and her mother lived while she still needed help. She couldn't open her eyes once we took her from children. We found a milk-mother for her. Even though she is beauitiful, nobody wanted her due to her color. Unfortunately tabby cats are not so wanted/adopted in Turkey. After 4 months, we got a call and the person on the phone said he wanted this poor girl only.  She had a lucky break and found one of the best home. She is very happy now with her forever home.",
        tr: "Sürmeli, annesiyle birlikte yaşadığı mahalledeki çocuklar tarafından henüz yardıma muhtaçken annesinden alındı. Onu çocuklardan aldığımızda gözlerini açılmamıştı. Ona bir süt anne bulduk. Güzel olmasına rağmen renginden dolayı kimse onu istemedi. Ne yazık ki tekir kediler Türkiye'de çok fazla istenmiyor ve sahiplenilmiyor. 4 ay sonra bir telefon aldık ve telefondaki kişi sadece bu zavallı kızı istediğini söyledi. Şansı yüzüne gülmüştü ve en iyi yuvadan birini buldu. Ömürlük yuvasında şimdi çok mutlu.",
      },
    },
    paris: {
      title: {
        eng: "Paris",
        tr: "Paris",
      },
      description: {
        eng: "Paris is a blind cat. Unfortunately, the neighbourhood she tried to live in was not an animal-friendly neighbourhood and we came across her during a street feed. When we found her, we couldn't believe how she survived like that all this time. We took her to the vet for the problem in her eyes, but we learned that she lost her eyesight due to the herpes virus in her infancy. The only thing we could do for her was to find a warm home and we did. She lives with her brothers in her forever home.",
        tr: "Paris görme engelli bir kedi. Maalesef yaşamaya çalıştığı muhit hayvansever bir muhit değildi ve bir sokak beslemesi sırasında karşımıza çıktı. Onu bulduğumuzda bunca zaman o halde nasıl hayatta kaldı inanamadık. Gözlerindeki sorun için veterinere götürdük ancak bebekliğinde herper virüsünden ötürü görme yetisini kaybettiğini öğrendik. Onun için tek yapabilecek şey sıcak bir yuva bulmaktı ve bulduk. Şu an iki kardeşiyle birlikte çok mutlu.",
      },
    },
    kartopu: {
      title: {
        eng: "Kartopu",
        tr: "Kartopu",
      },
      description: {
        eng: "Kartopu was a stray dog in Turkey trying to survive in a bad neighbourhood. Due to the fact that we got threats agains him, we placed him into a pet hotel. After 1 year, he found a home in Toronto, Canada. He is very happy with his family now. ",
        tr: "Kartopu, Türkiye'de kötü bir mahallede hayatta kalmaya çalışan bir sokak köpeğiydi. Kendisine yönelik tehditler aldığımız için onu bir evcil hayvan oteline yerleştirdik. 1 yıl sonra Toronto, Kanada'da bir ev buldu. Şimdi ailesiyle çok mutlu.",
      },
    },
    kaju: {
      title: {
        eng: "Kaju",
        tr: "Kaju",
      },
      description: {
        eng: "During a shelter visit, we saw Kaju. She was very afraid and sit in a corner of the shelter trying to understand what was going on. We learned that she had a family once but they abandoned her to shelter as they moved abroad. She had skin disease at first but she got better in a month. 2 months after her treathments, we found a home for her to be loved forever.",
        tr: "Bir barınak ziyareti sırasında Kaju'yu gördük. Çok korkmuş ve neler olduğunu anlamaya çalışarak sığınağın bir köşesine oturuyordu. Bir zamanlar ailesi olduğunu öğrendik ama yurt dışına taşınacakları için onu barınağa terketmişler. İlk başta cilt hastalığı vardı ama bir ayda iyileşti. Tedavisinin ardından 2 ay sonra sonsuza kadar sevileceği bir yuva bulduk.",
      },
    },
    gofrik: {
      title: {
        eng: "Gofrik",
        tr: "Gofrik",
      },
      description: {
        eng: "Gofrik was adopted when she was a kitten. However,  her family decided to abandoned her without any justification. She was only 6 months once we got her back. Her family just neglected her but we helped her to forget those bad days. She has a forever home now and sleeps with her mother in safe everyday.",
        tr: "Gofrik daha yavruyken evlat edinildi. Ancak ailesi hiçbir gerekçe göstermeden onu terk etmeye karar verdi. Onu geri aldığımızda sadece 6 aylıktı. Ailesi onu ihmal etse de ona o kötü günleri unutması için yardım ettik. Artık ömürlük bir evi var ve her gün annesiyle güvenli bir şekilde uyuyor.",
      },
    },
  };
  const HappyTailsBox = ({ image, content }) => {
    return (
      <div className="happy-tails-card">
        <div className="happy-tails-card-image">
          <img src={image} alt="happy dog"></img>
        </div>
        <div className="happy-tails-card-story">
          <h2>{language.lang === "eng" ? content.title.eng : content.title.tr}</h2>
          <p>{language.lang === "eng" ? content.description.eng : content.description.tr}</p>
        </div>
      </div>
    );
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container ">
        <div className="intro ">
          <h1>{language.lang === "eng" ? content.pageTitle.eng : content.pageTitle.tr}</h1>
          <p>{language.lang === "eng" ? content.intro1.eng : content.intro1.tr}</p>
          <p>{language.lang === "eng" ? content.intro2.eng : content.intro2.tr}</p>
        </div>
        <div className="happy-tails">
          <HappyTailsBox image={tales} content={cardContent.tales} />
          <HappyTailsBox image={fiona} content={cardContent.fiona} />
          <HappyTailsBox image={sebnem} content={cardContent.sebnem} />
          <HappyTailsBox image={shy} content={cardContent.shy} />
          <HappyTailsBox image={lucy} content={cardContent.lucy} />
          <HappyTailsBox image={cekuSans} content={cardContent.cekuSans} />
          <HappyTailsBox image={papyon} content={cardContent.papyon} />
          <HappyTailsBox image={paris} content={cardContent.paris} />
          <HappyTailsBox image={kartopu} content={cardContent.kartopu} />
          <HappyTailsBox image={kaju} content={cardContent.kaju} />
          <HappyTailsBox image={surmeli} content={cardContent.surmeli} />
          <HappyTailsBox image={gofrik} content={cardContent.gofrik} />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default HappyTails;
